//exams.js
import './exams.css'
import {useState, useEffect, useContext} from 'react';
import { useNavigate } from 'react-router-dom';
import Context from '../../user_details';
import AddExamForm from '../AddExamForm/AddExamForm';
import CloneExam from './cloneExam';
import axios from 'axios';
import Offcanvas from 'react-bootstrap/Offcanvas';
import SpinnerLoader from '../../helpers/spinner-loader';
import { setDatetoReadableFormat } from '../../helpers/examHelper';
import no_data_image from '../../assets/no-data.jpg';
import Pagination from '../../helpers/pagination';
import ExamSearchForm from './searchForm';
import {Modal, OffcanvasBody} from 'react-bootstrap';
import { playSound } from '../../helpers/otherSounds';

export default function Exams(){
    const [examsList, setExamList] = useState([]);
    const navigate = useNavigate();
    const {user} = useContext(Context);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [selectedExam, setSelectedExam] = useState({});
    const [selectedExamToDelete, setSelectedExamtoDelete] = useState('');
    const [examListLoader, setExamListLoader] = useState(true);
    const [isExamSelected, setIsExamSeleted] = useState(false);
    const [show, setShow] = useState(false);
    const [examsLandingPageLoader,setExamsLandingPageLoader] = useState(false);
    const [isSearching, setIsSearching] = useState(false);
    const [showSearchForm, setShowSearchForm] = useState(false);
    const [searchParams, setSearchParams] = useState({
        examName: '',
        client_id: '',
        selectedCourse: '',
        selectedExamPattern: '',
        startDate: '',
        endDate: ''
    });

    const [showEmailModal, setShowEmailModal] = useState(false);
    const [mailTableLoader, setMailTableLoader] = useState(false);
    const [emailStatusData, setEmailStatusData] = useState([]);
    const [viewedExam, setViewedExam] = useState("");
    const [showMessage, setShowMessage] = useState(false);
    const [infoMessage, setInfoMessage] = useState("");
    const [isExamConducted, setIsExamConducted] = useState(true);

    const handleSearchInputChange = (e) => {
        const { name, value } = e.target;
        setSearchParams(prevState => ({ ...prevState, [name]: value }));
    };
    
    useEffect(() => {
        getExamsList();
    } ,[page]);

    useEffect(() => {
        console.log("exam is selected", isExamSelected)
    }, [isExamSelected])

    useEffect(() => {
        if(Object.keys(examsList).length > 0) {
            setExamListLoader(false);
        }
    }, [examsList]);

    useEffect(() => {
        console.log("selected exam is ", selectedExam)
        if (Object.keys(selectedExam).length > 0) {
            setIsExamSeleted(true);
            document.getElementById("examEditModalToggler").click();
        }
    }, [selectedExam]);

    const examNavigator = async(examRoute) => {
        
        const hasPermission = await user.permissionTypes.includes("createCourses");
        if ( hasPermission) {
        setExamsLandingPageLoader(false);
        navigate(`/exams/${examRoute}`);}

    }
    
    const editQuestion = (event,exam) => {
       getExamDetails(exam._id);
    }

    const getExamDetails = async(examId) => {
        try{
            let exam = await axios.get(`${process.env.REACT_APP_API_URL}/exam/read/${examId}`);
            exam = exam.data
            let selectedExamPattern = await axios.get(`${process.env.REACT_APP_API_URL}/examPattern/read/${exam.selectedExamPattern}`);
            exam.selectedExamPattern = {...selectedExamPattern.data};
            setSelectedExam({...exam});
        }
        catch(e) {
            console.log(e)
        }
    }

    const getExamsListBySearch = async(e) => {
        e.preventDefault();
        getExamsList();
    };

    const getExamsList = async() => {
        
        setIsSearching(true)
        try {
            const result = await axios.get(`${process.env.REACT_APP_API_URL}/exam/list`, {
                params: {
                    page,
                    firm_id: user.firm_id,
                    type: user.user_type,
                    searchParams: JSON.stringify(searchParams)
                }
            });
            const { exams, totalPages } = result.data;
            setExamList(exams);
            setTotalPages(totalPages);
        } catch (e) {
            console.error(e);
        } finally {
            setIsSearching(false);
        }
    };

    const toggleSearchForm = () => {
        setShowSearchForm(!showSearchForm);
    };

    const resetSearch = () => {
        setSearchParams({
            examName: '',
            client_id: '',
            selectedCourse: '',
            selectedExamPattern: '',
            startDate: '',
            endDate: ''
        });
        getExamsList();  // Fetch the original exam list after resetting the search params
    };

    const updateModalClosed = async() => {
        const hasPermission = await user.permissionTypes.includes("createCourses");
        if ( hasPermission) {
        setIsExamSeleted(false);}
    }

    const deleteExam = async() => {
        const hasPermission = await user.permissionTypes.includes("createCourses");
        if ( hasPermission) {

        try {
            const result = await axios.patch(`${process.env.REACT_APP_API_URL}/exam/delete/${selectedExamToDelete}`);
            console.log(result.data)
            if(result.data.success === true) {
                getExamsList();
                document.getElementById("examDeleteSuccessMsgToggler").click();
                setTimeout(() => {
                    document.getElementById("examDeleteSuccessClose").click()
                }, 1000)
            }
        }
        catch(e) {
            const message = <div className='text-danger fw-medium border border-2 border-danger p-4'>Something went wrong</div>
            setInfoMessage(message);
            playSound('ERROR');
            setShowMessage(true);
            setTimeout(() => {
                setShowMessage(false);
            },2000)
        }}
    }

    const navigateToUpdateForm = (examId) => {
        navigate(`/exams/update/${examId}`);
    }

    const checkIsExamConducted = async(examId) => {
       const isExamConducted =  await axios.get(`${process.env.REACT_APP_API_URL}/exam/isConducted/${examId}`);
       return isExamConducted;
    }

    const editExam = async(examId) => {
       try {
        
        const hasPermission = await user.permissionTypes.includes("editExams");
        if ( hasPermission) {
            let isExamConducted = await checkIsExamConducted(examId);
            isExamConducted = isExamConducted.data;
            if(isExamConducted.success) {
                if (!isExamConducted.result) {
                    navigateToUpdateForm(examId);
                } else{
                    const message = <div className='text-danger fw-medium border border-2 border-danger p-4'>Cannot Edit the Exam That has been conducted </div>
                    playSound('ERROR');
                    setInfoMessage(message);
                    setShowMessage(true);
                    setTimeout(() => {
                        setShowMessage(false);
                    },2000);
                }
            }
        }
        else {
            throw new Error("Authentication revoked");
            
        }
       } catch (e) {
            const message = <div className='text-danger fw-medium border border-2 border-danger p-4'>Something went wrong </div>
            setInfoMessage(message);
            playSound('ERROR');
            setShowMessage(true);
            setTimeout(() => {
                setShowMessage(false);
            },2000)
       }
    };

    const emailSVG = (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope-check" viewBox="0 0 16 16">
        <path d="M2 2a2 2 0 0 0-2 2v8.01A2 2 0 0 0 2 14h5.5a.5.5 0 0 0 0-1H2a1 1 0 0 1-.966-.741l5.64-3.471L8 9.583l7-4.2V8.5a.5.5 0 0 0 1 0V4a2 2 0 0 0-2-2zm3.708 6.208L1 11.105V5.383zM1 4.217V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v.217l-7 4.2z"/>
        <path d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0m-1.993-1.679a.5.5 0 0 0-.686.172l-1.17 1.95-.547-.547a.5.5 0 0 0-.708.708l.774.773a.75.75 0 0 0 1.174-.144l1.335-2.226a.5.5 0 0 0-.172-.686"/>
      </svg>
    )

    const ViewExamEmailStatus = async(examId) => {
        try {
            const {data} = await checkIsExamConducted(examId);
            if(data.success) {
                setIsExamConducted(data.result);
                setTimeout(async() => {
                    setViewedExam(examId);
                    setShowEmailModal(true);
                    const response = await axios.get(`${process.env.REACT_APP_API_URL}/getEmailStatus/${examId}`);
                    setEmailStatusData(response.data.result);
                    setMailTableLoader(false);
                },1)
                
               } else {
                throw new Error("Something went Wrong");
            }
           
        }
        catch(e) {
            console.error(e)
            setEmailStatusData([]);
            setMailTableLoader(false);
            const message = <div className='text-danger fw-medium border border-2 border-danger p-4'>Something went wrong </div>
            setInfoMessage(message);
            playSound('ERROR');
            setShowMessage(true);
            setTimeout(() => {
                setShowMessage(false);
            },2000)
           
        } finally {
            
        }
    }

    const resendFailedEmails = async(exam_id) => {
        try {
            setMailTableLoader(true);
            const failedEmails = await Promise.all(emailStatusData.filter(data => (!data.status)));
            // console.log("before", emailStatusData, "after", failedEmails)
            const result = await axios.post(`${process.env.REACT_APP_API_URL}/resendExamLink`, {
                exam_id,
                emailStatusData: failedEmails,
                firm_id: user.firm_id
            })
            ViewExamEmailStatus(exam_id);
            
        }
        catch(e) {
            console.error(e);
        } finally {
            // login for finally {set loaders status if required}
        }
    }

    const resendAllEmail = async(exam_id) => {
        try {
            setMailTableLoader(true);
            const failedEmails = emailStatusData;
            // console.log("before", emailStatusData, "after", failedEmails)
            const result = await axios.post(`${process.env.REACT_APP_API_URL}/resendExamLink`, {
                exam_id,
                emailStatusData: failedEmails,
                firm_id: user.firm_id
            })
            ViewExamEmailStatus(exam_id);
        }
        catch(e) {
            console.error(e);
        } finally {
            // login for finally {set loaders status if required}
        }
    }

    const triggerFailedMessage = () => {
        const message = <div className='text-danger fw-medium border border-2 border-danger p-4'>Cannot Edit the Exam That has been conducted </div>
        setInfoMessage(message);
        playSound('ERROR');
        setShowMessage(true);
        setTimeout(() => {
            setShowMessage(false);
        }, 2000)
    }

    if (!examsLandingPageLoader) {
        return (
            <div className="exams-container exam-patterns-container p-3">
                <Modal show={showEmailModal} >
                    <Modal.Body className='d-flex flex-column px-2 align-items-stretch gap-2'>
                        
                        <table className='nexus-table-primary-stripped'>
                            <thead>
                                <td className='w-75'>Email</td>
                                <td>Status</td>
                            </thead>
                            <tbody>
                                {mailTableLoader?
                                    <tr>
                                       <td className='w-100' colSpan={2}>
                                            <div className='d-flex align-items-center w-100 justify-content-center'>
                                                <SpinnerLoader/>
                                            </div>
                                       </td>
                                    </tr>
                                    :
                                        emailStatusData.length > 0 ?
                                           emailStatusData.map(data => (
                                            <tr>
                                                <td>{data.email}</td>
                                                {data.status && <td className='text-success'>✅</td>}
                                                {!data.status && <td className='text-danger'>✘</td>}
                                            </tr>
                                           )):<tr>
                                            <td className='p-5' colSpan={2}>
                                                No data Found
                                            </td>
                                           </tr>
                                            
                                    
                                }
                            </tbody>
                        </table>
                        <div className='d-flex align-items-center gap-2 justify-content-end'>
                            <button className='nexus-button-danger-outline' onClick={() => {setShowEmailModal(false); setViewedExam("")}}>Close</button>
                            <button className='nexus-button-primary ' 
                                onClick={() => {
                                    
                                    resendFailedEmails(viewedExam)
                                }} 
                                disabled={isExamConducted}
                            >   Resend Failed Emails
                            </button>
                            <button className='nexus-button-primary ' onClick={() => {resendAllEmail(viewedExam)}} disabled={isExamConducted}>Resend All</button>
                        </div>
                    </Modal.Body>
                </Modal>
                
                {/* ========= Header and Search container - STARTS here ========== */}
                <div class="">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="page-header-container">
                                <h2 class="page-main-header">Exams</h2>
                                
                                {user.permissionTypes.includes("createExams") &&  
                                    <div className='btn btn-primary exam-patterns-search-btn p-2' 
                                    onClick={()=>{examNavigator("create")}}>
                                        &nbsp;
                                        <span className="left-menu-icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-hourglass-split" viewBox="0 0 16 16">
                                                <path d="M2.5 15a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1zm2-13v1c0 .537.12 1.045.337 1.5h6.326c.216-.455.337-.963.337-1.5V2zm3 6.35c0 .701-.478 1.236-1.011 1.492A3.5 3.5 0 0 0 4.5 13s.866-1.299 3-1.48zm1 0v3.17c2.134.181 3 1.48 3 1.48a3.5 3.5 0 0 0-1.989-3.158C8.978 9.586 8.5 9.052 8.5 8.351z" />
                                            </svg>
                                        </span>&nbsp;Add New Exam&nbsp;</div>}
                            </div>
                        </div>
                        <div class="col-md-4 search-icon-div"> 
                            <svg onClick={toggleSearchForm} xmlns="http://www.w3.org/2000/svg" width="25" height="40" fill="currentColor" className="bi bi-search float-end" viewBox="0 0 16 16">
                                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                            </svg>
                        </div>
                    </div>

                    <div>
                        {/* Search form */}
                        {showSearchForm && (
                            <ExamSearchForm searchParams={searchParams} resetSearch={resetSearch} handleSearchInputChange={handleSearchInputChange} getExamsListBySearch={getExamsListBySearch}/>
                        )}
                    </div>
    
                    <div class="row">
                    <div class="col-sm-12"> <p class="mb-0">&nbsp;</p> </div>
                    </div>
                </div>
    
                {/* ========= Header and Search container - Ends here ========== */}
                
               
               <div className="exams-list row row-cols-1 row-cols-md-3 g-3">
                {!isSearching ?
                    examsList.length > 0 ? (<> 
                        {examsList.map((exam,index)=>{
                            return (
                            <div key={index} className='exam-card000 page-added-item col'>
                                <div class="added-items-list card h-100">
                                    <div class="card-body">
                                        <div onClick={()=>{examNavigator(exam._id)}} >
                                            <h5 class="card-title min-ht-25">{exam.examName}</h5>
                                            {!examListLoader && 
                                            <div className='exam-reports-container' >
                                                {user.user_type == 'superAdmin' && <p className='card-text mb-1'>
                                                    <strong>Firm:</strong>
                                                    <span className='report-value' >{exam.firm}</span>  
                                                </p>}
                                                <p className='card-text mb-1'>
                                                    <strong>Course:</strong>
                                                    <span className='report-value' >{exam.selectedCourse.course_name}</span>  
                                                </p>
                                                <p className='card-text mb-1'>
                                                    <strong>Exam Pattern: </strong> 
                                                    <span className='report-value' >{exam.selectedExamPattern.exam_pattern_name}</span>
                                                </p>
                                                <p className='card-text mb-1'>
                                                    <strong>Sections:</strong> 
                                                    <span className='report-value' >{exam.selectedExamPattern.no_of_sections}</span>
                                                </p>
                                                <p className='card-text mb-1'>
                                                    <strong>Start Date:</strong>  
                                                    <span className='report-value' >{setDatetoReadableFormat(exam.startDate)}</span>
                                                </p>
                                                <p className='card-text mb-1'>
                                                    <strong>End Date:</strong> 
                                                    <span className='report-value' >{setDatetoReadableFormat(exam.endDate)}</span>
                                                </p>
                                                <p className='card-text mb-1'>
                                                    <strong>Timezone:</strong> 
                                                    <span className='report-value' >{exam.timezone}</span>
                                                </p>
                                            </div>}
                                        </div>
                                    </div>
                                    <div class="card-footer">
                                        <div className='text-center d-flex align-items-center flex-wrap justify-content-center'>
                                            {user.permissionTypes.includes("editExams") &&  
                                                    <div className='btn exams-list-delete_item'  
                                                        onClick={() => {editExam(exam._id)}}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                                                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                                                <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"/>
                                                            </svg> &nbsp; Edit
                                                    </div>}
                                            {user.permissionTypes.includes("deleteExams") &&  
                                                    <div className='btn exams-list-delete_item' 
                                                        data-bs-toggle="modal" data-bs-target="#examDeleteModal" 
                                                        onClick={() => setSelectedExamtoDelete(exam._id)}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                                                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
                                                                <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
                                                            </svg> &nbsp; Delete
                                                    </div>}
                                            {(user.permissionTypes.includes("createExams") || user.permissionTypes.includes("editExams")) &&  
                                                    <button className='btn exams-list-delete_item' 
                                                        onClick={() => ViewExamEmailStatus(exam._id)} title='Email Status'>
                                                           {emailSVG}
                                                    </button>}
                                            {/*<CloneExam examId={exam._id} getExamsList={getExamsList} />*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            )
                        })} 
                        
                        <div class="row col-md-12 text-center">
                            <Pagination totalPages={totalPages} page={page} setPage={setPage} />
                        </div>
                        
                    </>):
                     <div className='d-flex justify-content-center align-items-stretch h-100 w-100 p-3 border border-3 border-muted'>
                         <img className="img-fluid" src={no_data_image} alt='No Data Found' />
                     </div>                    
                    : 
                    // <div>Loading.......</div>
                    <div className="ht60vh w-100 d-flex justify-content-center align-items-center">
                        <SpinnerLoader />
                    </div>
                    }
               </div>
                {/* ========================================================= */}
    
               {/* ---------------------------Exam Edit modal ---------------------------- */}
               <button className='d-none' data-bs-toggle="modal" data-bs-target="#examEditModal" id="examEditModalToggler"/>
                <div className="modal"  data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" id="examEditModal">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header text-secondary">
                                
                            </div>
                            <div className="modal-body">
                                {isExamSelected? Object.keys(selectedExam).length > 0 && <AddExamForm selectedExam={selectedExam}/>:"Loading.. please wait..."}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-outline-danger" data-bs-dismiss="modal" onClick={updateModalClosed}>Close</button>
                                <button type="button" className="btn btn-outline-success" data-bs-dismiss="modal" onClick={updateModalClosed}>Save changes</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* ----------------------------------------------------------------------- */}
                {/* ----------delete Exam modal warning ------------------------------------ */}
                <div className="modal" tabindex="-1" id="examDeleteModal">
                    <div className="modal-dialog">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title text-danger">Are you sure?</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <p>Deleting this exam will result into no longer availability of this exam. Do you really wish to proceed to delete the exam?</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-light" data-bs-dismiss="modal">cancel</button>
                            <button type="button" className="btn btn-danger" onClick={deleteExam}>Confirm</button>
                        </div>
                        </div>
                    </div>
                </div>
                {/* ------------------------------------------------------------------------- */}
                {/*--------------------------- Successful exam delete modal message -----------------------------*/}
                <button type="button" className="btn btn-primary d-none" id="examDeleteSuccessMsgToggler" data-bs-toggle="modal" data-bs-target="#examDeleteMsg"></button>
                <div className="modal fade" id="examDeleteMsg" tabIndex="-1" role="dialog" aria-labelledby="examDeleteMsgLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                    <div className="modal-content" style={{left:'100%'}}>
                        <div className="modal-body d-flex align-items-center bg-white text-success justify-content-between">
                        <div>Exam deleted successfully!</div>
                        <div className='btn p-0 px-1' data-bs-dismiss="modal" id="examDeleteSuccessClose" aria-label="Close"> &times;</div>
                        </div>
                    </div>
                    </div>
                </div>
                {/* ----------------------------------------------------------------------------------------*/}
                <Offcanvas show={show} onHide={() => { setShow(false) }} placement='end' style={{ height: 'fit-content' }}>
                    <Offcanvas.Body className='text-danger'>
                        The organisation did not provide the neccessary details for smtp services. please wait until the smtp service is set up by the organisation.
                    </Offcanvas.Body>
                </Offcanvas>
                {/* ------- info message pop up modal ---------- */}
                <Offcanvas show={showMessage} placement='end' style={{ height: 'fit-content' }}>
                    <OffcanvasBody className='p-0'>
                        {infoMessage}
                    </OffcanvasBody>
                </Offcanvas>
            </div>
        )
    }
    else {
       return <div className='h-100 w-100 d-flex justify-content-center align-items-stretch'>
             <div className="text-center text-primary fw-bolder fs-2">
                <div className="spinner-border" role="status"></div>
            </div>
        </div>
    }
}