import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { playSound } from '../../helpers/otherSounds';
import Context from "../../user_details";

export default function AddTopicForm({ onAddTopic , selectedTopics, cancelBtnHandler }) {
  const context = useContext(Context);
    const [topicObj, setTopicObj] = useState({  
    selectedClient: selectedTopics?.clientDetails?._id || (context.user.user_type === 'superAdmin' ? "" : context.user.firm_id),
    selectedCourse: selectedTopics?.subject_id?.course_id?.[0] || "",
    selectedSubject: selectedTopics?.subject_id?._id || "",
    topicName: selectedTopics?.topic_name || "",
  });
  const [courses, setCourses] = useState([]);
  const [subjects, setSubjects] = useState([]); 
  const [formType,setFormType] = useState('Add');
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  const [clients, setClients] = useState([]);
 
  useEffect(() => {
  }, [topicObj])
   useEffect(() => {
    if (selectedTopics) {
      setTopicObj({
        selectedClient: selectedTopics?.clientDetails?._id || "",
        selectedCourse: selectedTopics?.subject_id?.course_id?.[0] || "",
        selectedSubject: selectedTopics?.subject_id?._id || "",
        topicName: selectedTopics?.topic_name || "",
        _id: selectedTopics?._id || ""
      });
      setFormType('Update');
      if (selectedTopics?.subject_id?._id) {
        fetchSubjects(selectedTopics?.subject_id?._id);
      }
    }
  }, [selectedTopics]);

  // useEffect(() => {
  //   fetchSubjects(topicObj.selectedCourse);
  // }, []);
  useEffect(() => {
    if (topicObj.selectedCourse) {
      fetchSubjects(topicObj.selectedCourse);
    }
  }, [topicObj.selectedCourse]);
  

  const fetchSubjects = async (courseId) => {
    if (!courseId) return;
		try {
			const response = await axios.get(`${process.env.REACT_APP_API_URL}/qb/subjects/search/${courseId}`);
			setSubjects(response.data);
		} catch (error) {
      setSubjects([])
			console.error("Error fetching subjects:", error);
		}
	};

  useEffect(() => {
    fetchClients();
  }, []);
  
  const fetchClients = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/qb/subscriptions`);
      if (response.data.clientsums) {
        let clients = Object.keys(response.data.clientsums).map((d) => {
          return { _id: d, firm_name: response.data.clientsums[d] };
        });
        setClients(clients || []);
      } else {
        setMessage("Clients data not available. You may need to create Clients.");
      }
    } catch (error) {
      console.error("Error fetching clients:", error);
    }
  };

	useEffect(() => {
		fetchCourses();
	}, []);

  const fetchCourses = async () => {
		try {
			const response = await axios.get(`${process.env.REACT_APP_API_URL}/qb/courses/clientId/${topicObj.selectedClient}`);
			if (response.data && response.data.courses) {
				setCourses(response.data.courses);
			}
		} catch (error) {
			console.error("Error fetching courses:", error);
		}
	};

  const handleClientChange = async (e) => {
		var clientId = e.target.value;
		setTopicObj((prevState) => {
			return {
				...prevState,
				selectedClient: clientId,
				selectedCourse: "",
				selectedSubject: "",
				selectedTopic: "",
			};
		});
		// Fetch courses based on the selected client
		try {
			const response = await axios.get(`${process.env.REACT_APP_API_URL}/qb/courses/clientId/${clientId}`);
			if (response.data && response.data.courses && response.data.courses.length > 0) {
				setCourses(response.data.courses);
				setMessage("");
			} else {
				setMessage("Courses data not available for the selected CLient. You may need to create Courses for the selected Client.");
			}
		} catch (error) {
			console.error("Error fetching courses:", error);
		}
	};
	
	const handleCourseChange = (e) => {
		const courseId = e.target.value;
		setTopicObj((prevState) => {
			return {
				...prevState,
				selectedCourse: courseId,
				selectedSubject: "",
				selectedTopic: "",
			};
		});
		fetchSubjects(courseId);
	};

	const handleSubjectChange = (e) => {
		const subjectId = e.target.value;
		setTopicObj((prevState) => {
			return {
				...prevState,
				selectedSubject: subjectId,
				selectedTopic: "",
			};
		});
		//fetchTopics(subjectId);
	};

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/qb/topics`, { 
        topic_name: topicObj.topicName,
        subject_id: topicObj.selectedSubject // Include selected subject ID in the payload
      });
      console.log('New topic added:', response.data);
      setMessage('Topic added successfully!');
      setMessageType('success');
      playSound('SUCCESS');
      setTimeout(() => { onAddTopic() }, 1000);
    } catch (error) {
      console.error('Error adding topic:', error);
      setMessage('Error adding topic.');
      setMessageType('error');
      playSound('ERROR');
    }
  };

  const handleEditSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.patch(`${process.env.REACT_APP_API_URL}/qb/topics/${topicObj._id}`, { 
          topic_name: topicObj.topicName,
        subject_id: topicObj.selectedSubject // Include selected subject ID in the payload
      });
    
      console.log('Topic updated:', response.data);
      setMessage('Topic updated successfully!');
      setMessageType('success');
      playSound('SUCCESS');
      setTimeout(() => { onAddTopic() }, 1000);
    } catch (error) {
      console.error('Error updating topic:', error);
      setMessage('Error updating topic.');
      setMessageType('error');
      playSound('ERROR');
    }
  };

  return (
    <div className="add-topic-form general-add-form-container">
      <h3>Add Topic</h3>
      <form onSubmit={handleSubmit}>
      {  context.user.user_type === "superAdmin" &&
              <div className="form-group mb-0">
              <label htmlFor="client" className="form-label">
                     Select Client:
                      </label>
                <select id="client" className="form-select" value={topicObj.selectedClient} onChange={handleClientChange} 
                disabled={selectedTopics}
                required>
                <option value="">Select Client</option>
                  {clients.map((client) => (
                  <option key={client._id} value={client._id}>
                      {client.firm_name}
                        </option>
                       ))}
                      </select>
                        </div>
              }
          
               <div className="form-group vertical-middle mb-0">
          <label>Select Course:</label>
          <select id="course" className="form-select" value={topicObj.selectedCourse} onChange={handleCourseChange} required>
												<option value="">Select Course</option>
												{Array.isArray(courses) &&
													courses.map((course) => (
														<option key={course._id} value={course._id}>
															{course.course_name}
														</option>
													))}
											</select>
                   </div>
          
                   <div>
                      <label>Select Subject:</label>
                      <select class="form-select"
                        value={topicObj.selectedSubject}
                        onChange={handleSubjectChange}
                        required
                      >
                        <option value="">Select Subject</option>
                        {Array.isArray(subjects) && 
                        subjects.map(subject => (
                          <option key={subject._id} value={subject._id}>{subject.subject_name}</option>
                        ))}            
                      </select>
                    </div>

                  <div>
                    <label>Topic Name:</label>
                    <input
                      type="text"
                      value={topicObj.topicName}
                      onChange={(e) =>
                        setTopicObj((prevState) => {
                          return { ...prevState, topicName: e.target.value };
                        })
                      }
                      required
                    />
                  </div>
               
        {/* <button type="submit">Add Topic</button> */}
        <div>
               {
              formType === 'Add' ? <button type="submit" className="add-form-btn">
                          <span class="icon-placeholder">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-plus" viewBox="0 0 16 16">
                <path d="M8 6.5a.5.5 0 0 1 .5.5v1.5H10a.5.5 0 0 1 0 1H8.5V11a.5.5 0 0 1-1 0V9.5H6a.5.5 0 0 1 0-1h1.5V7a.5.5 0 0 1 .5-.5"/>
                <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5z"/>
              </svg>
            </span>
            {formType} 
             Now
          </button>:
            <button onClick={handleEditSubmit} className="add-form-btn">
              {/* <span class="icon-placeholder">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                    <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"/>
                  </svg> &nbsp;
            </span> */}
            {formType}  Now
          </button>
           }
          <button class="cancel-form-btn" onClick={cancelBtnHandler}>
            <span class="icon-placeholder">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z"/>
              </svg>
            </span>
            Cancel
          </button>
        </div>
        {/* {formType === 'Add' && <p>All Fields are Mandatory</p>} */}
        <div className='w-100 text-center'>
          <i className='text-danger text-center'>All Fields are Mandatory*</i>
          </div>
      </form>
      {message && (
        <div className={`message ${messageType}`}>
          {message}
        </div>
      )}
      {/* {errorMessage && <p className="text-danger">{errorMessage}</p>} */}
    </div>
  );
}
