import React, { useState } from 'react';
import CKEditor from 'react-ckeditor-component';

const heightVar = window.innerHeight - 190;
const toolbarConfig = {
  height: heightVar,
  toolbar: 'Full',
  allowedContent: true,
  startupFocus: true,
  allowedContent: true, // Allow all HTML content
  extraAllowedContent: '<%= %>',
  versionCheck : false
};

const CKeditor = ({ content, setBody }) => {
  const [editorContent, setEditorContent] = useState(content);

  const onChange = (evt) => {
    console.log("onChange fired with event info: ", evt);
    const newContent = evt.editor.getData();
    setEditorContent(newContent);
    setBody(newContent);
  };

  return (
    <div>
      <CKEditor
        activeClass="p10"
        config={toolbarConfig }
        content={editorContent}
        events={{
          change: onChange,
        }}
      />
    </div>
  );
};

export default CKeditor;
