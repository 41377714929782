import './App.css';
import './App.scss';
// import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from './pages/Login/login';

import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import Context from './user_details';
import ForgotPassword from './components/ForgotPassword/forgotPassword';
import { BrowserRouter } from 'react-router-dom';
import permissionsHelper from './helpers/permissionsHelper'
import {routes, generateRouter} from './helpers/router';
import SpinnerLoader from './helpers/spinner-loader';
import SpinnerLoaderMainScreen from './helpers/spinner-loader-main-screen';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import {getPermissionIdByPermissionType} from './helpers/permissionsHelper';
import { Suspense } from 'react';
import NotFound from './pages/NotFound/NotFound';
import axios from 'axios';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [loader, setLoader] = useState(true);
  const [filteredRoutes, setFilteredRoutes] = useState([]);
  
  useEffect(() => {
    getUserData();
  }, []);

  useEffect(() => {
    if(Object.keys(userDetails).length > 0) {
      // console.log(userDetails," user details")
      setIsLoggedIn(true);
      // setLoader(false);
      filterDefaultRouter();
    }
    // console.log("user details in app",userDetails)
  }, [userDetails])

  const SwitchLoginStatus = (Status) => {
    setIsLoggedIn(Status);
    // Cookies.set("nexus_exam_login", Status, { expires: 9999 })
    if (Object.keys(userDetails).length === 0 && Status) {
      getUserData();
    }
  }

  const getUserData = async () => {
    if (Cookies.get("nexus_exam_id")) {
      await fetch(process.env.REACT_APP_API_URL + "/ums/admin/read/" + Cookies.get("nexus_exam_id"))
      .then(async response => {
        const data = await response.json();
        if (data.success) {
          const allPermissionsIds = await permissionsHelper.modifyAndReturnPermissionIds(data.result.selectedRoles);
          var allUserPermissionTypes = [];
          await Promise.all(
            allPermissionsIds.map(async permissionId => {
              const permissionType = await permissionsHelper.getPermissionTypeByPermissionId(permissionId);
              if (permissionType) {
                allUserPermissionTypes.push(permissionType);
              }
            })
          );
    
          let profile_image = null; // Default to null
          try {
            const { data: profileImage, headers } = await axios.get(`${process.env.REACT_APP_API_URL}/ums/admin/profile-image/${data.result._id}`, { responseType: 'arraybuffer' });
    
            const imageBlob = new Blob([profileImage], { type: headers['content-type'] });
            const reader = new FileReader();
    
            reader.onloadend = () => {
              const contentType = headers['content-type']; // Get the content type
              profile_image = `data:${contentType};base64,${reader.result.split(',')[1]}`; // Format correctly
              console.log("this is profile image", profile_image);
              setUserDetails({
                ...data.result,
                permissions: allPermissionsIds,
                permissionTypes: allUserPermissionTypes,
                profile_image: null // This will be null if there's an error
              });
            };
    
            reader.onerror = () => {
              console.error("Error: Unable to convert profile image to base64");
              profile_image = null; // Ensure null if conversion fails
              setUserDetails({
                ...data.result,
                permissions: allPermissionsIds,
                permissionTypes: allUserPermissionTypes,
                profile_image // This will be null if there's an error
              });
            };
    
            reader.readAsDataURL(imageBlob); // Trigger conversion
          } catch (e) {
            console.error("Error: Unable to get profile image");
          }
        } else {
          Cookies.remove("nexus_exam_id");
          setIsLoggedIn(false);
          setLoader(false);
        }
      })
      .catch(err => {
        Cookies.remove("nexus_exam_id");
        alert("something went wrong");
      });
    

    }
    else {
      setLoader(false);
    }
  }

  const hasPermission = async (permissionType) => {
    const permissionId = await getPermissionIdByPermissionType(permissionType);
    return userDetails.permissions.includes(permissionId);
  };
  
  const checkRoute = async (route) => {
    const permissionCheck = route.permissionType == ''? true:await hasPermission(route.permissionType);
    // console.log("this is route", route)
    const userTypeCheck   = route.userTypes?.length? route.userTypes.some(userType => userType == userDetails.user_type) : true
    if (permissionCheck && userTypeCheck) {
      if (route.subRoutes && route.subRoutes.length) {
        const subRoutes = await Promise.all(
          route.subRoutes.map(async (subRoute) => {
            const isSubRouteEligible = await checkRoute(subRoute);
            return isSubRouteEligible ? subRoute : null;
          })
        );
        // Filter out null values from the subRoutes
        route.subRoutes = subRoutes.filter(subRoute => subRoute !== null);
        return route.subRoutes.length > 0 ? route : null;
      } else {
        return route;
      }
    } else {
      return null;
    }
  };
  
  const filterDefaultRouter = async () => {
    if (!routes) {
      return [];
    } else {
      const filteredRoutes = await Promise.all(
        routes.map(async (route) => {
          const isRouteEligible = await checkRoute(route);
          return isRouteEligible ? route : null;
        })
      );
      // Filter out null values from the filteredRoutes
      const availableRoutes = filteredRoutes.filter(route => route !== null);
      
      // setFilteredRoutes(() => {
      //   setLoader(false);
      //   return availableRoutes;
      // });
      const filtered_routes = await generateRouter(filteredRoutes);
      console.log("Filtered routes:", filtered_routes);
      setFilteredRoutes(() => {
        setLoader(false);
        return filtered_routes;
      });
      
    }
  };



  if (!loader) {
    if (!isLoggedIn) {
      return (
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Login isLoggedIn={isLoggedIn} SwitchLoginStatus={SwitchLoginStatus} setUserDetails={setUserDetails} />} />
            <Route path="forgotPassword" element={<ForgotPassword />} />
            <Route path="*" element={<Login />} />
          </Routes>
        </BrowserRouter>
      )
    }
    else {
      if(Object.keys(userDetails).length > 0) {
        console.log((filteredRoutes))
        return (
          <Context.Provider value={{ user: userDetails }}>
            <Router>
              <Routes>

              {filteredRoutes}
               <Route path="*" element={<NotFound/>}/>
              </Routes>
            </Router>
          </Context.Provider>
        )
      }
      else {
        // getUserData();
        return (
        <div className="ht100vh w-100 d-flex justify-content-center align-items-center">
          <SpinnerLoader />
        </div>
        )
      }
     
    }
  }
  else {
    return (
    // <div className="ht100vh w-100 d-flex justify-content-center align-items-center">
    //   <SpinnerLoader />
    //   <p>test</p>
    // </div>

    <div className="ht60vh w-100 d-flex justify-content-center align-items-center main-screen-loader">
        <SpinnerLoaderMainScreen x={'sample-class-name'} />
        <p className='loader-message'>Please wait. It'll just take a moment...</p>
    </div>
    )
  }
}

export default App;
