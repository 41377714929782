import "./users.css";
import { useEffect, useState, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import edit_icon from '../../assets/edit_icon.svg';
import delete_icon from '../../assets/delete_icon.svg';
import cancel_icon from '../../assets/close_icon.svg';
import Context from '../../user_details';
import SpinnerLoader from '../../helpers/spinner-loader';
import no_data_image from '../../assets/no-data.jpg';
import Pagination from '../../helpers/pagination';
import BulkUpload from "./bulkUpload";
import SearchForm from "./searchForm";
import UserEmailStatus from "./emailStatus";
import axios from "axios";
import resendMailIcon from "../../assets/resendMail.svg";
import { Offcanvas, Modal } from "react-bootstrap";


export default function User(props) {
    const [users, setUsers] = useState([]);
    const [page, setPage] = useState(1);
    const [limit] = useState(10); // items per page
    const [totalPages, setTotalPages] = useState(1);
    const [users_list, setUsersList] = useState(undefined);
    const [deleted_users, setDeletedUsers] = useState(new Set());
    const userDetails = useContext(Context);
    const [loading, setLoading] = useState(true);
    const [allUsersList, setAllUsersList] = useState([]);
    const [isSearching, setIsSearching] = useState(false);
    const navigate = useNavigate();
    const [showSearchForm, setShowSearchForm] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [emailStatusData, setEmailStatusData] = useState({});
    const [searchParams, setSearchParams] = useState({
        name: "",
        firm: "",
        role: "",
        group: "",
        email: "",
      });
    const [showOffcanvas, setShowOffcanvas] = useState(false);
    const [offcanvasMessage, setOffCanvasMessage] = useState("");
    const [showResendMailModal, setShowResendMailModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState({});

    useEffect(() => {
        get_users_list(page, limit);
    }, [page]);

    useEffect(() => {
        setUsersList([...allUsersList]);
    }, [allUsersList])


    const resetSearch = () => {
        setSearchParams({
            name: "",
            firm: "",
            role: "",
            group: "",
            email: "",
          });
        get_users_list();  // Fetch the original exam list after resetting the search params
    };
    

    const get_users_list = async () => {
        try {
            setLoading(true);
            var allEmails;
            const usersList = await fetch(`${process.env.REACT_APP_API_URL}/ums/admin/paginated-list/?type=${userDetails.user.user_type}&firm_id=${userDetails.user.firm_id}&page=${page}&limit=${limit}&searchParams=${JSON.stringify(searchParams)}`)
            .then(async res => {
                let result = await res.json();
                allEmails = result.resultsPromise;
                setAllUsersList(result.resultsPromise);
                setTotalPages(result.totalPages);
            }).catch(err => {
                console.error(err);
                allEmails = [];
                setAllUsersList([]);
                setLoading(false);
            })
            if(allEmails.length) {
                allEmails = await Promise.all(allEmails.map(details => {
                    const {email, firm_id} = details.user;
                    return {email,firm_id}
                }));
                const {data:userEmailStatus} = await axios.post(`${process.env.REACT_APP_API_URL}/getUsersEmailStatus?type=${userDetails.user.user_type}`,{
                    users: allEmails,
                });
                setEmailStatusData(userEmailStatus.resultEmailStatus)
            }
        } catch(e) {
            setAllUsersList([]);
            alert("something went wrong in fetching users list")
        } finally {
            setLoading(false);
        }
    }

    const CreateProfile = () => {
        if (!userDetails.user.permissionTypes.includes("createUsers")) return;
        navigate("create-profile")
    }
    const UpdateProfile = (userId, firmId) => {
        if (!userDetails.user.permissionTypes.includes("editUsers")) return;
        navigate(`update-profile?userId=${userId}&firmId=${firmId}`)
    }

    const deleteProfile = async (event) => {
        if (!userDetails.user.permissionTypes.includes("deleteUsers")) return;
        let del_users = deleted_users;
        var selected_user = event.target;
        var delete_user_id = event.target.parentNode.getAttribute("value");
        if (selected_user.tagName !== 'IMG') {
            selected_user = selected_user.childNodes[0];
            delete_user_id = event.target.getAttribute("value");
        }
        if (selected_user.className === "delete_icon") {
            selected_user.src = cancel_icon;
            selected_user.className = "cancel_icon";
            del_users.add(delete_user_id);

        }
        else {
            selected_user.src = delete_icon;
            selected_user.className = "delete_icon";
            if (del_users.has(delete_user_id)) del_users.delete(delete_user_id)
        }
        setDeletedUsers(del_users);
        navigate("/users");
        console.log("this is deleted users,", deleted_users)
    }

    const delete_users = async () => {
        for (let user of deleted_users) {
            var result = await fetch(process.env.REACT_APP_API_URL + "/ums/admin/delete/" + user, {
                method: "DELETE"
            })
                .then(async res => {
                    return await res.json()
                })
            console.log(result);

            setDeletedUsers(new Set());
            await get_users_list();
            navigate("/users");
        }
    }

    const toggleSearchForm = () => {
        setShowSearchForm(!showSearchForm);
    };

    const resendLoginEmail = async(user) => {
        try{
            setLoading(true);
            const {email, firm_id, first_name, last_name, _id} = user.user
            const {data: response} = await axios.post(`${process.env.REACT_APP_API_URL}/resendLoginDetailsEmail`, {_id, email, firm_id, first_name, last_name, loggedin_user_firm_id: userDetails.user.firm_id, updated_by: userDetails.user._id});
            setShowOffcanvas(true);
            const successOffcanvas = (<div className="text-success fw-medium p-4 m-1">
                <i>Login Credentials sent to {email} successfully</i>
            </div>)
            setOffCanvasMessage(successOffcanvas);
            setEmailStatusData(prev => ({...prev, [email] : true}))
        }
        catch(e) {
            setShowOffcanvas(true);
            const errorOffcanvas = (<div className="text-white bg-danger fw-medium p-4 border border-danger">
                <i>Something Went Wrong. Unable to Resend Email.</i>
            </div>)
            setOffCanvasMessage(errorOffcanvas);
            setEmailStatusData(prev => ({...prev, [user.user.email] : false}))
           
        } finally {
            setTimeout(() => {
                setShowOffcanvas(false);
            },1500)
            setLoading(false);
        }
    }

    const toggleEmailTriggerModal = (showModalValue,user) => {
        setShowResendMailModal(showModalValue);
        setSelectedUser(user);
    }

    const emailSVG = (<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M20.5 19.7361C19.9692 20.2111 19.2684 20.5 18.5 20.5C16.8431 20.5 15.5 19.1569 15.5 17.5C15.5 15.8431 16.8431 14.5 18.5 14.5C19.8062 14.5 20.9175 15.3348 21.3293 16.5M22 14V17H19M11.5 19H6.2C5.0799 19 4.51984 19 4.09202 18.782C3.71569 18.5903 3.40973 18.2843 3.21799 17.908C3 17.4802 3 16.9201 3 15.8V8.2C3 7.0799 3 6.51984 3.21799 6.09202C3.40973 5.71569 3.71569 5.40973 4.09202 5.21799C4.51984 5 5.0799 5 6.2 5H17.8C18.9201 5 19.4802 5 19.908 5.21799C20.2843 5.40973 20.5903 5.71569 20.782 6.09202C21 6.51984 21 7.0799 21 8.2V10M20.6067 8.26229L15.5499 11.6335C14.2669 12.4888 13.6254 12.9165 12.932 13.0827C12.3192 13.2295 11.6804 13.2295 11.0677 13.0827C10.3743 12.9165 9.73279 12.4888 8.44975 11.6335L3.14746 8.09863" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>)

    if (true) {
        return (
            userDetails && userDetails != null && userDetails != undefined ?
                <div className="user_page p-3">
                    <Offcanvas show={showOffcanvas} placement="end" style={{height: "fit-content"}}>
                        <Offcanvas.Body className="p-0">
                            {offcanvasMessage}
                        </Offcanvas.Body>
                    </Offcanvas>
                    <Modal show={showResendMailModal}>
                        <Modal.Header>
                            <p className="nexus-color-red fw-bold mb-0">Warning</p>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="fw-medium">
                                Please note that resending the email will reset the account password to its default setting.
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="d-flex justify-content-end align-items-center gap-2">
                                <button className="nexus-button-danger" onClick={() => {
                                    setSelectedUser({});
                                    setShowResendMailModal(false);
                                }}>
                                    Cancel
                                </button>
                                <button className="nexus-button-primary" onClick={() => {
                                    resendLoginEmail(selectedUser);
                                    setShowResendMailModal(false);
                                }}>
                                    Proceed
                                </button>
                            </div>
                        </Modal.Footer>
                    </Modal>
                    <BulkUpload showModal={showModal} setShowModal={setShowModal} get_users_list={get_users_list}/>
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-sm-8">
                                <div class="page-header-container d-flex gap-3">
                                    <h2 class="page-main-header">Users</h2>
                                    {deleted_users.size > 0 &&  userDetails.user.permissionTypes.includes("deleteUsers") && 
                                        <div onClick={delete_users} className="btn btn-danger">Delete</div>
                                    }
                                    {
                                        userDetails.user.permissionTypes.includes("createUsers") && 
                                        <button className="btn btn-primary add-form-btn" onClick={CreateProfile}>
                                        <span class="icon-placeholder">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-person-plus-fill" viewBox="0 0 16 16">
                                                <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6"/>
                                                <path fill-rule="evenodd" d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5"/>
                                            </svg>
                                        </span>
                                            Add User
                                        </button>
                                    }
                                    {
                                        userDetails.user.permissionTypes.includes("createUsers") && 
                                        <button className="btn btn-primary add-form-btn" onClick={() => {setShowModal(true)}} >
                                            <span class="icon-placeholder">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-person-plus-fill" viewBox="0 0 16 16">
                                                    <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6"/>
                                                    <path fill-rule="evenodd" d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5"/>
                                                </svg>
                                            </span>
                                            Upload Users
                                        </button>   
                                    }
                                    {
                                        userDetails.user.permissionTypes.includes("createUsers") && false &&
                                        <UserEmailStatus/> 
                                    }
                                </div>
                            </div>
                            <div class="col-sm-4 search-icon-div">
                                <svg onClick={toggleSearchForm} xmlns="http://www.w3.org/2000/svg" width="25" height="40" fill="currentColor" className="bi bi-search float-end" viewBox="0 0 16 16">
                                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                                </svg>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12"> <p class="mb-0">&nbsp;</p> </div>
                        </div>
                    </div>
                    
                    {showSearchForm && (
                        <SearchForm user={userDetails.user} searchParams={searchParams} setSearchParams={setSearchParams} resetSearch={resetSearch} get_users_list={get_users_list}/>
                    )}

                    {/* ========= Header and Search container - Ends here ========== */}
                    <div className="p-0 rounded">
                        <table className="nexus-table-primary-stripped w-100">
                            {/* ----table headers-------- */}
                            <thead className=" w-100">
                                <td className="fw-medium">Name</td>
                                {userDetails?.user?.user_type === 'superAdmin' ? <td className="fw-medium">Firm</td> : ""}
                                <td className="fw-medium">Role</td>
                                <td className="fw-medium">Batches</td>
                                <td className="fw-medium">Email</td>
                                <td className="fw-medium">Type</td>
                                <td className="fw-medium">Email Status</td>
                                <td className="fw-medium">Actions</td>
                            </thead>
                            {/* ---table body ----------- */}
                            <tbody className="user_details_table nexus-label-500">
                                {
                                    loading?
                                        <tr>
                                            <td colSpan={`${userDetails.user.user_type === 'superAdmin'? 8:7}`}>
                                                <div className="h-100 w-100 d-flex justify-content-center bg-white align-items-center">
                                                    <div className="text-center text-primary fw-bolder fs-2">
                                                        <div className="spinner-border" role="status"></div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        : 
                                            isSearching?
                                            // <div>Loading... </div>
                                            <tr>
                                                <td colSpan={`${userDetails.user.user_type === 'superAdmin'? 8:7}`}>
                                                    <div className="ht60vh w-100 d-flex justify-content-center align-items-center">
                                                        <SpinnerLoader />
                                                    </div>
                                                </td>
                                            </tr>
                                            :
                                                users_list.length === 0 && users_list ?
                                                <tr>
                                                    <td colSpan={`${userDetails.user.user_type === 'superAdmin'? 8:7}`}>
                                                        <div style={{ height: "90%" }} className="no_user bg-white d-flex flex-column justify-content-center align-items-center">
                                                            <img className="img-fluid" src={no_data_image} alt='No Data Found' />
                                                        </div>
                                                    </td>
                                                </tr>
                                                :
                                                
                                                    users_list.length == 0 ? 'No Users Found' : users_list.map((user, index) => {
                                                        return (
                                                            <tr key={index} className="">
                                                                <td >{user?.user?.first_name} {user?.user?.last_name}</td>
                                                                {userDetails && userDetails?.user?.user_type === 'superAdmin' && <td >{user.firmDetails?.firm_name} </td>}
                                                                <td>{
                                                                    user?.roles?.map((role, index) => {
                                                                        return <span key={index}>{role.role_name}<br /></span>
                                                                    })
                                                                }

                                                                </td>
                                                                <td>
                                                                    {
                                                                        user?.user?.groups_assigned?.map((group, index) => {
                                                                            return <span key={index}>{group.name}<br /></span>
                                                                        })
                                                                    }

                                                                </td>
                                                                <td >{user?.user?.email}</td>
                                                                <td >{user?.user?.user_type}</td>
                                                                <td>{emailStatusData[user.user.email]? <>✅</> : <>❌</>}</td>
                                                                <td >
                                                                <div className="d-flex align-items-center px-auto flex-wrap row-gap-2">
                                                                        {
                                                                            userDetails.user.permissionTypes.includes("editUsers") &&
                                                                            <button className="user_action_button update_button border-none" title="Resend Email" onClick={() => { toggleEmailTriggerModal(true, user) }}>
                                                                                <img src={resendMailIcon} height={20}/>
                                                                            </button>
                                                                        } 
                                                                        {
                                                                            userDetails.user.permissionTypes.includes("editUsers") &&
                                                                            <div className="user_action_button update_button" onClick={() => { UpdateProfile(user?.user._id, user?.user.firm_id) }}>
                                                                                <img className="edit_icon" src={edit_icon} height="18" />
                                                                            </div>
                                                                        }
                                                                        {userDetails.user.permissionTypes.includes("deleteUsers") &&
                                                                            <div className="user_action_button  delete_button" value={user?.user?._id} id={user?.user?._id} onClick={deleteProfile}>
                                                                                <img className="delete_icon" src={delete_icon} height="18" />
                                                                            </div>
                                                                        }
                                                                    
                                                                </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                
                                }

                            </tbody>
                            {/* ------------------------ */}
                        </table>
                    </div>
                    <div class="row col-md-12 text-center">
                        <Pagination totalPages={totalPages} page={page} setPage={setPage} />
                    </div>
                </div> : <div className="h-100 w-100 d-flex justify-content-center bg-white align-items-center">
                    <div className="text-center text-primary fw-bolder fs-2">
                        <div className="spinner-border" role="status"></div>
                    </div>
                </div>
        )
    }
    else {
        <div className='h-100 w-100 d-flex justify-content-center align-items-center'>
            <div className="text-center text-primary fw-bolder fs-2">
                <div className="spinner-border" role="status"></div>
            </div>
        </div>
    }
}
