import React from 'react';
import SoundButton from './soundButton';

const Pagination = ({ totalPages, page, setPage }) => {
  return (
    <nav>
      <ul className="pagination">
        {/* Show the first page */}
        <li className={`page-item ${page === 1 ? 'active' : ''}`}>
          <SoundButton className="page-link" onClick={() => setPage(1)}>1</SoundButton>
        </li>

        {/* Show dots if current page is far from the first page */}
        {page > 3 && <li className="page-item disabled"><span className="page-link">...</span></li>}

        {/* Show pages around the current page */}
        {Array.from({ length: 5 }, (_, i) => page - 2 + i).filter(p => p > 1 && p < totalPages).map(pageNumber => (
          <li key={pageNumber} className={`page-item ${page === pageNumber ? 'active' : ''}`}>
            <SoundButton className="page-link" onClick={() => setPage(pageNumber)}>
              {pageNumber}
            </SoundButton>
          </li>
        ))}

        {/* Show dots if current page is far from the last page */}
        {page < totalPages - 2 && <li className="page-item disabled"><span className="page-link">...</span></li>}

        {/* Show the last page */}
        {totalPages > 1 && (
          <li className={`page-item ${page === totalPages ? 'active' : ''}`}>
            <SoundButton className="page-link" onClick={() => setPage(totalPages)}>{totalPages}</SoundButton>
          </li>
        )}
      </ul>
    </nav>
  );
};

export default Pagination;
