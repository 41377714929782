// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* subjects.css */
.subjects-container {
  /* margin: 20px auto;
  max-width: 800px;
  padding: 0 20px; */
  padding: 25px;
}

.subject-list {
  list-style-type: none;
  padding: 0;
}

.subject-item {
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 10px;
}

.subject-name {
  font-weight: bold;
}

.created-date {
  color: #666;
  font-size: 0.9em;
}

.pagination {
  margin-top: 20px;
}

.page-item {
  display: inline-block;
  margin-right: 5px;
}

.page-link {
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f0f0f0;
  cursor: pointer;
}

.page-link.active {
  background-color: #007bff;
  color: #fff;
  border-color: #007bff;
}
`, "",{"version":3,"sources":["webpack://./src/components/Subjects/subjects.css"],"names":[],"mappings":"AAAA,iBAAiB;AACjB;EACE;;oBAEkB;EAClB,aAAa;AACf;;AAEA;EACE,qBAAqB;EACrB,UAAU;AACZ;;AAEA;EACE,sBAAsB;EACtB,kBAAkB;EAClB,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;EACrB,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;EACjB,sBAAsB;EACtB,kBAAkB;EAClB,yBAAyB;EACzB,eAAe;AACjB;;AAEA;EACE,yBAAyB;EACzB,WAAW;EACX,qBAAqB;AACvB","sourcesContent":["/* subjects.css */\n.subjects-container {\n  /* margin: 20px auto;\n  max-width: 800px;\n  padding: 0 20px; */\n  padding: 25px;\n}\n\n.subject-list {\n  list-style-type: none;\n  padding: 0;\n}\n\n.subject-item {\n  border: 1px solid #ccc;\n  border-radius: 5px;\n  margin-bottom: 10px;\n  padding: 10px;\n}\n\n.subject-name {\n  font-weight: bold;\n}\n\n.created-date {\n  color: #666;\n  font-size: 0.9em;\n}\n\n.pagination {\n  margin-top: 20px;\n}\n\n.page-item {\n  display: inline-block;\n  margin-right: 5px;\n}\n\n.page-link {\n  padding: 5px 10px;\n  border: 1px solid #ccc;\n  border-radius: 5px;\n  background-color: #f0f0f0;\n  cursor: pointer;\n}\n\n.page-link.active {\n  background-color: #007bff;\n  color: #fff;\n  border-color: #007bff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
