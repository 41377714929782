import React, {useState, useEffect} from 'react';
import './header.css'; // Import CSS file
// import logo from '../../assets/logo-white.png';
import logo from '../../assets/nexsaa-white-logo.png';
import userMenuIcon from '../../assets/profile.svg';
import Cookies from 'js-cookie';
import { useContext } from 'react';
import Context from '../../user_details';
import axios from 'axios';

export default function Header() {
  const {user} = useContext(Context);
  const [profilePic, setProfilePic] = useState(null);

  useEffect(() => {
    if(user.profile_image) {
      getProfileImage();
    }
  }, [])

  const logout = ()=>{
    Cookies.remove("nexus_exam_id");
  }

  const getProfileImage = async() => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/ums/admin/profile-pic-url/${user.profile_image}`);
      setProfilePic(response.data.url);
    } catch(e) {
       console.error(e);
    }
  }
    const getInitials = (first_name, last_name) => {
      return `${first_name?.charAt(0) || ''}${last_name?.charAt(0) || ''}`.toUpperCase();
  };

  return (
    <header className="header">
      <div className="logo">
        <img src={logo} alt="Logo" />
      </div>
      <div className="dropdown user-info ">
        
        <div className="d-flex justify-content-center align-items-center " type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          {user.profile_image ? 
            <div className='p-2 rounded-circle border border-3 border-muted' style={{background: `url(${profilePic})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'contain'}}></div>
            :
            <div className="p-2 rounded-circle border border-3 border-muted" style={{backgroundColor: '#000066', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'contain'}}>
            {getInitials(user.first_name, user.last_name)} {/* Show initials here */}
          </div>
           }
        </div>
        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <a className="dropdown-item" href="/My-profile" >My Profile</a>
          <a className="dropdown-item" href="/" onClick={logout}>Logout</a>
        </div>
        <p className="p-0 m-0 pe-2 header-username">Welcome, {user.first_name} {user.last_name}</p>
      </div>
    </header>
  );
}
