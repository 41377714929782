import React, { useState, useContext, useEffect } from "react";
import Context from '../../user_details';
import axios from 'axios';

const QuestionSearchForm = ({ searchParams, setPage, handleSearchInputChange, fetchQuestions, resetSearch }) => {
    const { user } = useContext(Context);
    const [clientsUms, setClientsUms] = useState([]);
    const [courses, setCourses] = useState([]);
    const [subjects, setSubjects] = useState([]);
    const [topics, setTopics] = useState([]);
    const [examPatterns, setExamPatterns] = useState([]);
    const [questionTypesWithComplexities, setQuestionTypesWithComplexities] = useState(["Multiple Choice", "True / False","Checkbox"]);
    const [questionTypes, setQuestionTypes] = useState([
        {label: "Multiple Choice", value : 1},
        {label: "True / False", value : 2},
        {label: "Checkbox", value : 3}
    ]);
    const [complexityLabels, setComplexityLabels] = useState([
        {label: "Easy", value : 1},
        {label: "Medium", value : 2},
        {label: "Hard", value : 3}
    ])

    const [aiGeneratedFilterOptions, setAiGEneratedFilterOptions] = useState([
        {label: "true", value: 'true'},
        {label: "false", value:"false"}
    ])


    useEffect(() => {
        const fetchClients = async () => {
            if (user.user_type === 'superAdmin') {
                try {
                    const response = await axios.get(`${process.env.REACT_APP_API_URL}/ums/firms/list`);
                    if (response && response.data && response.data.success) {
                        setClientsUms(response.data.results || []);
                    }
                } catch (error) {
                    console.error('Error fetching clients:', error);
                }
            }
        };
        fetchClients();
    }, []);

    useEffect(() => {
        const fetchCourses = async () => {
            const firmId = searchParams.client_id;

            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/courses/list/${firmId}`);
                if (response.data && response.data.result && response.data.result.courses) {
                    setCourses(response.data.result.courses);
                }
            } catch (error) {
                console.error('Error fetching courses:', error);
            }
        };

        if(searchParams.client_id) fetchCourses();
    }, [searchParams.client_id]);

    useEffect(() => {
        const fetchSubjects = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/subjects/list/${searchParams.course_id}`);
                if (response.data) {
                    setSubjects(response.data);
                } else {
                    throw new Error("Unable to fetch subjects")
                }
            } catch (error) {
                console.error('Error fetching subjects:', error);
            }
        };

        if (searchParams.course_id) fetchSubjects();
    }, [searchParams.course_id]);

    useEffect(() => {
        const fetchTopics = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/topics/list/${searchParams.subject_id}`);
                if (response.data && response.data.result) {
                    setTopics(response.data.result);
                }
            } catch (error) {
                console.error('Error fetching topics:', error);
            }
        };

        if(searchParams.subject_id) fetchTopics();
    }, [searchParams.subject_id]);

    const checkIsQuestionHasComplexity = () => {
        return questionTypes.some(questionType => {
            if (questionType.value != searchParams.question_type) {
                return false;
            } else {
                return questionTypesWithComplexities.some(questionTypeWithComplexity => questionTypeWithComplexity == questionType.label);
            }
        })
    }

    const submitForm = (e) => {
        e.preventDefault();
        setPage(1);
        fetchQuestions(1);
        console.log("preventing default and setting page to 1")
    }

    return (
        <div className="general-add-form-container mt-3">
            <form onSubmit={submitForm}>
                <div className="row row-gap-3">
                    <div className="col-md-4">
                        <input
                            type="text"
                            name="question"
                            placeholder="Question Name"
                            className="form-control"
                            value={searchParams.question}
                            onChange={handleSearchInputChange}
                        />
                    </div>
                    {user.user_type === 'superAdmin' && (
                        <div className="col-md-4">
                            <select
                                name="client_id"
                                className="form-control"
                                value={searchParams.client_id}
                                onChange={handleSearchInputChange}
                            >
                                <option value="">Select Client</option>
                                {clientsUms.map(client => (
                                    <option key={client._id} value={client._id}>
                                        {client.firm_name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    )}
                    <div className="col-md-4">
                        <select
                            name="course_id"
                            className="form-control"
                            value={searchParams.course_id}
                            onChange={handleSearchInputChange}
                        >
                            <option value="">Select Course</option>
                            {courses.map(course => (
                                <option key={course._id} value={course._id}>
                                    {course.course_name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-md-4">
                        <select
                            name="subject_id"
                            className="form-control"
                            value={searchParams.subject_id}
                            onChange={handleSearchInputChange}
                        >
                            <option value="">Select Subject</option>
                            {subjects.map((subject,i) => (
                                <option key={i} value={subject._id}>
                                    {subject.subject_name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-md-4">
                        <select
                            name="topic_id"
                            className="form-control"
                            value={searchParams.topic_id}
                            onChange={handleSearchInputChange}
                        >
                            <option value="">Select Topic</option>
                            {topics.map((topic,i) => (
                                <option key={i} value={topic._id}>
                                    {topic.topic_name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-md-4">
                        <select
                            name="question_type"
                            className="form-control"
                            value={searchParams.question_type}
                            onChange={handleSearchInputChange}
                        >
                            <option value="">Select Question Type</option>
                            {questionTypes.map((questionType,i) => (
                                <option key={i} value={questionType.value}>
                                    {questionType.label}
                                </option>
                            ))}
                        </select>
                    </div>
                    {
                        searchParams.question_type && 
                        checkIsQuestionHasComplexity() &&
                        <div className="col-md-4">
                        <select
                            name="complexity"
                            className="form-control"
                            value={searchParams.complexity}
                            onChange={handleSearchInputChange}
                        >
                            <option value="">Select Complexity</option>
                            {complexityLabels.map((complexity, i) => (
                                <option key={i} value={complexity.value}>{complexity.label}</option>
                             )
                            )}
                        </select>
                    </div> 
                    }

                    <div className="col-md-4">
                        <select
                            name="ai_generated"
                            className="form-control"
                            value={searchParams.ai_generated}
                            onChange={handleSearchInputChange}
                        >
                            <option value="">Select AI generated</option>
                            {aiGeneratedFilterOptions.map((filter,i) => (
                                <option key={i} value={filter.value}>
                                    {filter.label}
                                </option>
                            ))}
                        </select>
                    </div>
                   
                    <div className="col-md-12 mt-3 text-center">
                        <button type="submit" className="app-btn-primary">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                            </svg>
                            &nbsp;Search
                        </button>
                        <button type="button" className="cancel-form-btn" onClick={resetSearch}>Reset</button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default QuestionSearchForm;
