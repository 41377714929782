// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ck-editor {
  height: 100% !important;
  word-break: break-word;
}
.ck-editor__main {
  height: 100%;
  max-height: 100% !important;
}
.ck-editor__editable {
  height: 100%;
  max-height: 16rem !important;
}
.ck-editor__editable p {
  max-height: 100% !important;
}`, "",{"version":3,"sources":["webpack://./src/components/QuestionForm/ckEditor/ckEditor.scss"],"names":[],"mappings":"AAIA;EACI,uBAAA;EAGA,sBAAA;AALJ;AAMI;EACI,YAAA;EACA,2BAAA;AAJR;AAMI;EACI,YAAA;EACA,4BAAA;AAJR;AAMQ;EACI,2BAAA;AAJZ","sourcesContent":["@mixin flex-column {\n    display: flex;\n    flex-direction: column;\n}\n.ck-editor {\n    height: 100% !important;\n    // width: 100% !important;\n    \n    word-break: break-word ;\n    &__main {\n        height: 100%;\n        max-height: 100% !important;\n    }\n    &__editable {\n        height: 100%;\n        max-height: 16rem !important; \n        // overflow-y: auto !important;\n        p {\n            max-height: 100% !important;\n            // overflow-y: auto;\n        }\n       \n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
