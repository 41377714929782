// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Topics.css */
.topics-container {
  padding: 25px;
}

.topic-list {
  list-style-type: none;
  padding: 0;
}

.topic-item {
  margin-bottom: 10px;
}

.topic-name {
  font-weight: bold;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.page-item {
  margin: 0 5px;
}

.page-link {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.page-link:hover {
  background-color: #0056b3;
}
`, "",{"version":3,"sources":["webpack://./src/components/Topics/topics.css"],"names":[],"mappings":"AAAA,eAAe;AACf;EACE,aAAa;AACf;;AAEA;EACE,qBAAqB;EACrB,UAAU;AACZ;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,gBAAgB;AAClB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,yBAAyB;EACzB,WAAW;EACX,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":["/* Topics.css */\n.topics-container {\n  padding: 25px;\n}\n\n.topic-list {\n  list-style-type: none;\n  padding: 0;\n}\n\n.topic-item {\n  margin-bottom: 10px;\n}\n\n.topic-name {\n  font-weight: bold;\n}\n\n.pagination {\n  display: flex;\n  justify-content: center;\n  margin-top: 20px;\n}\n\n.page-item {\n  margin: 0 5px;\n}\n\n.page-link {\n  background-color: #007bff;\n  color: #fff;\n  border: none;\n  padding: 5px 10px;\n  border-radius: 5px;\n  cursor: pointer;\n}\n\n.page-link:hover {\n  background-color: #0056b3;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
