import React from 'react';
import './header.css'; // Import CSS file
// import logo from '../../assets/logo-white.png';
import logo from '../../assets/nexsaa-white-logo.png';
import userMenuIcon from '../../assets/profile.svg';
import Cookies from 'js-cookie';
import { useContext } from 'react';
import Context from '../../user_details';

export default function Header() {
  const {user} = useContext(Context);

  const logout = ()=>{
    Cookies.remove("nexus_exam_id");
  }
  return (
    <header className="header">
      <div className="logo">
        <img src={logo} alt="Logo" />
      </div>
      <div className="dropdown user-info">
        
        <div className="d-flex justify-content-center align-items-center" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          {user.profile_image ? 
            <div className='p-2 rounded-circle' style={{background: `url(${user.profile_image})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover'}}></div>
            :
            <img src={userMenuIcon} height={19}/>
          }
        </div>
        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <a className="dropdown-item" href="/My-profile" >My Profile</a>
          <a className="dropdown-item" href="/" onClick={logout}>Logout</a>
        </div>
        <p className="p-0 m-0 pe-2 header-username">Welcome, {user.first_name} {user.last_name}</p>
      </div>
    </header>
  );
}
