import { useEffect, useState, useContext } from 'react';
import * as XLSX from 'xlsx';
import Context from '../../user_details';
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import Offcanvas from 'react-bootstrap/Offcanvas';
import SpinnerLoader from '../../helpers/spinner-loader';
import sampleUserUploadFormat from '../../assets/sampleUserUploadFormat.xlsx';
import { playSound } from '../../helpers/otherSounds';


export default function BulkUpload({ showModal, setShowModal, get_users_list }) {

    const userDetails = useContext(Context);
    const [canvasShow, setCanvasShow] = useState(false);
    const [errorCanvas, setErrorCanvas] = useState(false);
    const [bulkCheckLoader, setBulkCheckLoader] = useState(false);
    const [unsuccessfullusersCreation, setUnsuccefullUsersCreation] = useState([]);
    const [bulkUserMailAvailability, setBulkUserMailAvailability] = useState({});

    const [isbulkdataLoaded, setBulkLoaded] = useState(false);
    const [groupsList, setGroupsList] = useState([]);

    const [usersUploadedList, setUsersUploadedList] = useState([]);
    const [firmsList, setFirmsList] = useState([]);

    const [bulkUserAddForm, setBulkUserAddForm] = useState({
        firm_id: userDetails.user.user_type === 'superAdmin'? '' : userDetails.user.firm_id,
        group_id: "",
    });

    const [errorCanvasMessage, setErrorCanvasMessage] = useState(
        <div className="text-danger p-3 ">Please upload a valid file format: .csv or .xlsx</div>
    );

    useEffect(() => {
        if(userDetails.user.user_type === 'superAdmin') {
            const get_firms_list = async() => {
                try {
                    const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/ums/firms/list`);
                    setFirmsList(data.results);
                } catch(e) {
                    console.error(e);
                    setFirmsList([]);
                    alert("unable to fetch firms List")
                }
            }
            get_firms_list();
        } else {
            get_firm_groups(userDetails.user.firm_id);
        } 
    }, [userDetails.user.user_type]);

    const get_firm_groups = async(firmId) => {
        try {
            const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/ums/groups/list?firm_id=${firmId}&type=user`);
            setGroupsList(data)
        } catch(e) {
            console.error(e);
            setGroupsList([])
        }
    }

    useEffect(() => {
        if (usersUploadedList.length > 0) {
            const checkEmails = async() => {
                try {
                    setBulkCheckLoader(true);
                    const {data} = await axios.post(`${process.env.REACT_APP_API_URL}/ums/checkEmailAvailability`, {
                        usersUploadedList,
                        firm_id: bulkUserAddForm.firm_id
                    })
                    setBulkUserMailAvailability(data);

                } catch(e) {
                    console.error(e);
                    let temp = await Promise.all(
                        usersUploadedList.map(user => ({email: user.email, available: false}))
                    )
                    setBulkUserMailAvailability(temp);
                } finally {
                    setBulkCheckLoader(false);
                }
            }
            checkEmails();
        }
    }, [usersUploadedList])

    useEffect(() => {
        if(errorCanvas) {
            setTimeout(() => {
                setErrorCanvas(false);
            }, 2000)
        } 
    } , [errorCanvas]);

    const bulkUploadUsers = async() => {
        const bulkUploadButton = document.getElementById("bulk-upload-input");
        bulkUploadButton.click();
    }

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        const allowedExtensions = /(\.csv|\.xlsx)$/i;

        if (file && !allowedExtensions.exec(file.name)) {
            // alert('Please upload a valid file format: .csv or .xlsx');
            event.target.value = ''; // Clear the input
            setErrorCanvas(true);
            setUsersUploadedList([]);
            return;
        }
    
        if (file) {
            try {
                setBulkLoaded(false);
                const reader = new FileReader();
                reader.onload = async(e) => {
                    const binaryStr = e.target.result;
                    const workbook = XLSX.read(binaryStr, { type: 'binary' });
                    const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
                    const jsonData = XLSX.utils.sheet_to_json(firstSheet, { header: 1 }); // Read as array of arrays
                    // Process first row only (without headers)
                    if (jsonData.length > 0) {
                        let tempData = []
                            await Promise.all(
                                jsonData.map(row => {
                                    if (row.length >= 3) { // Ensure there are exactly 3 columns
                                        const structuredData = {
                                        first_name: row[0],
                                        last_name: row[1],
                                        email: row[2],
                                        };
                                        if (!tempData.includes(structuredData)) {
                                            tempData.push(structuredData);
                                        }
                                    }
                                })
                            )
                        setUsersUploadedList(tempData);
                    }
                };
      
                reader.readAsBinaryString(file); // Read file as binary string
            } catch(e) {
                console.error(e);
                setUsersUploadedList([]);
            } finally {
                setBulkLoaded(true);
            }
        } 
    };

    const createBulkUsers = async() => {
       try {
            setBulkCheckLoader(true);
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/admin/createBulkUsers`,{
                usersUploadedList,
                bulkUserMailAvailability,
                firm_id: bulkUserAddForm.firm_id,
                group_id: bulkUserAddForm.group_id,
                loggedin_user_firm_id: userDetails.user.firm_id,
                created_by: userDetails.user._id
            })
            if(response.data && response.data.unsuccessfullEmails && (response.data.unsuccessfullEmails).length > 0 ) {
                playSound('ERROR');
                setUnsuccefullUsersCreation(response.data.unsuccessfullEmails)
            }
       } catch(e) {
            console.error(e);
            playSound('ERROR');
            setUnsuccefullUsersCreation(Object.keys(bulkUserMailAvailability));
       } finally {
            get_users_list();
            playSound('SUCCESS');
            setCanvasShow(true);
            setTimeout(function(){
                setCanvasShow(false);
            }, 2000);
            initializebulkUploadForm();
       }
    }

    const initializebulkUploadForm = () => {
        setBulkUserAddForm({firm_id: userDetails.user.user_type === 'superAdmin'? '' : userDetails.user.firm_id,group_id: "",})
        setBulkUserMailAvailability([]);
        // setUnsuccefullUsersCreation([]);
        setUsersUploadedList([]);
        setBulkCheckLoader(false);
        setShowModal(false);
        document.getElementById("bulk-upload-input").value = "";
    }


return (<>
    <Modal show={showModal}>
                        <Modal.Body>
                            <div className="container general-add-form-container">
                                <div className="row gap-3 mb-3">
                                    {userDetails.user.user_type == 'superAdmin' && <div className="col-4 nexus-label-500 ">Select Firm <sup className="nexus-color-red"> *</sup></div>}
                                    {userDetails.user.user_type == 'superAdmin' && 
                                             <select id="firms-dropdown" className="col-6 nexus-dropdown" value={bulkUserAddForm.firm_id} onChange={(e) => {
                                                if (e.target.value) {
                                                    setBulkUserAddForm(prev => ({...prev,firm_id: e.target.value, group_id: ""}));
                                                    get_firm_groups(e.target.value)
                                                } else {
                                                    setBulkUserAddForm(prev => ({...prev,firm_id:"", group_id: ""}));

                                                }
                                             }}>
                                                <option value="">--- Select Firm ---</option>
                                                {firmsList.map(firm => (<option value={firm._id}>{firm.firm_name}</option>))}
                                             </select>
                                    }
                                </div>
                                <div className="row gap-3 mb-3">
                                    <div className="col-4 nexus-label-500"> Select Group <sup className="nexus-color-red"> *</sup></div>
                                    <select className="col-6 nexus-dropdown" value={bulkUserAddForm.group_id} onChange={(e) => {
                                        setBulkUserAddForm(prev => ({...prev,group_id: e.target.value}));
                                    }}>
                                    <option value="">--- Select Group ---</option>
                                    {groupsList?.map(group => (<option value={group.group._id}>{group.group.name}</option>))}
                                    </select>
                                </div>
                                <div className="row gap-3 mb-3 ">
                                    <div className="col-4 nexus-label-500">Upload CSV<sup className="nexus-color-red"> *</sup></div>
                                    <div className=" col-7 small">
                                        <a title="Download Sample Template"  download="sampleUserUploadFormat.xlsx" href={sampleUserUploadFormat} className="text-underline-none col-12 d-flex gap-2 mb-2 nexus-color-primary-note align-items-center small" style={{textDecoration: 'none'}}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download" viewBox="0 0 16 16">
                                                <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>
                                                <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z"/>
                                            </svg>
                                            <p className="m-0" >Download Sample Template</p>
                                        </a>
                                        <input type="file" className="" id="bulk-upload-input" accept=".csv,.xlsx"  onChange={handleFileChange}/>
                                    </div>
                               </div>
                                
                               {usersUploadedList.length > 0 && (
                                    <div className="row pad-10">
                                        {bulkCheckLoader ? 
                                        <div className="w-100 h-100 d-flex align-items-center justify-content-center">
                                            <SpinnerLoader/>
                                            <span>Checking uploaded emails for duplicates, please wait...</span>
                                        </div>
                                        :
                                        
                                        <table className="nexus-table-primary-stripped">
                                            <thead>
                                                <td>Email</td>
                                                <td>Availability Status</td>
                                            </thead>
                                            <tbody>
                                                {Object.keys(bulkUserMailAvailability).map(email => (
                                                <tr>
                                                    <td>{email}</td>
                                                    <td>
                                                        {bulkUserMailAvailability[email] ? 
                                                            <span style={{ color: 'green' }}>Available ✅</span> : 
                                                            <span style={{ color: 'red' }}>Already Registered ⭕</span>
                                                        }
                                                    </td>
                                                </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        }
                                    </div>
                                )}
                               
                                <div className=" flex-row-reverse gap-2 text-center">
                                    <button className="nexus-button border-1 border text-danger border-danger"  onClick={() => {initializebulkUploadForm()}} >
                                        Cancel
                                    </button>
                                    <button className="nexus-button-primary mar-L-10" onClick={createBulkUsers} disabled={!isbulkdataLoaded || bulkCheckLoader || !document.getElementById("bulk-upload-input") || !usersUploadedList.length || !(bulkUserAddForm.firm_id && bulkUserAddForm.group_id)}>
                                        Add Users
                                    </button>
                                </div>
                            </div>
                            
                        </Modal.Body>
                       
                     </Modal>
                    {/* Info Canvas */}
                    <Offcanvas show={canvasShow} placement='end' style={{ height: `${unsuccessfullusersCreation.length > 0 ? "100vh": 'fit-content'}` }}>
                        <Offcanvas.Header closeButton onClick={() => {setCanvasShow(false);setUnsuccefullUsersCreation([]);}}></Offcanvas.Header>
                        <Offcanvas.Body>
                            <div className="">
                                {unsuccessfullusersCreation.length > 0 ? 
                                   <div>
                                     <p className="fw-bold text-danger">Some of the users have not been created </p>
                                    <table className="nexus-table-primary-stripped w-100 h-100">
                                        <thead>
                                            <td>Emails</td>
                                        </thead>
                                        <tbody>
                                            {unsuccessfullusersCreation.map(email => (
                                                <tr>
                                                    <td>{email}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                   </div> : <p className="fw-bold text-success">✔ Successfully created all users</p>
                                }
                            </div>
                        </Offcanvas.Body>
                    </Offcanvas>
                    {/* Error Canvas */}
                    <Offcanvas show={errorCanvas} backdrop={false} placement="end" style={{height: "fit-content"}}>
                        <Offcanvas.Body className="border border-2 border-danger">
                            {errorCanvasMessage}
                        </Offcanvas.Body>
                    </Offcanvas>
    
    
    
    
</>)};