import { playSound } from '../../helpers/otherSounds';

const ExamFormValidator = async({setShowValidationCanvas, setValidationCanvasMessage, sectionValues}) => {
 const sectionValidationKeys = [{key: "section_name"}, {key: "selectedTopic"}, {key: "selectedSubject"}];
 const questionTypes = [
    {label: "Multiple Choice", type: 1, complexities: true},
    {label: "True / False", type: 2, complexities: true},
    {label: "Checkbox", type: 3, complexities: true},
    {label: "Comprehensive", type: 4 ,complexities: false},
    {label: "File Uplaod", type: 5, complexities: false}
 ]
 const complexities = [{label: "easy", value: 1}, {label: "medium", value: 2}, {label: "hard", value: 3}];
 var messageKey; 
 const validationCanvasMessages = {
    section_name: (
        <div className="p-3 text-danger">
            Please Provide section names.
        </div>
    ),
    selectedTopic: (
        <div className="p-3 text-danger">
            Please select topic in every section.
        </div>
    ),
    selectedSubject: (
        <div className="p-3 text-danger">
            Please provide Subjects to sections.
        </div>
    ),
    insufficientQuestions: (
        <div className="p-3 text-danger">
            Please provide sufficient question per section to pick.
        </div>
    )

 }
 var validation = false;
 const sectionKeysValidator = async() => {
    var validation = true;
    for(let section of sectionValues) {
        try {
            for(let validator of sectionValidationKeys) {
                if(!(section[validator.key])){
                    messageKey= validator.key;
                    throw new Error(validator.key);
                }
            }
        }catch(key) {
            // alert( `${messageKey} here`)
            playSound('ERROR');
            setValidationCanvasMessage(validationCanvasMessages[messageKey]);
            setShowValidationCanvas(true);
            setTimeout(() => {
                setShowValidationCanvas(false);
            }, 2000);
            validation = false;
            break;
        }
    }
    return validation;
 }

 const questionAssignValidator = async() => {
    var validation = true;
    for(let section of sectionValues) {
        try {
            var {assignedQuestions} = section;
            assignedQuestions = assignedQuestions[0];
            for(let questionType of questionTypes) {
                let error_found = false;
                switch(questionType.label) {
                    case "Multiple Choice":
                    case "True / False": 
                    case "Checkbox": {
                        let assigned_questions  = assignedQuestions[questionType.type];
                        for(let complexity of complexities) {
                            const {question_ids, pick} = assigned_questions[complexity.value];
                            if (question_ids.length < pick) {
                                error_found = true;
                                break;
                            }
                        }
                        break;
                    }
                }
                if (error_found) {
                    messageKey = "insufficientQuestions";
                    throw new Error("insufficientQuestions")
                }
            }
            
        } catch(error) {
            // alert(key)
            // alert( `${messageKey} here`)
            playSound('ERROR');
            setValidationCanvasMessage(validationCanvasMessages[messageKey]);
            setShowValidationCanvas(true);
            setTimeout(() => {
                setShowValidationCanvas(false);
            }, 2000);
            validation = false;
            break;
        }
    }
    return validation;
 }

 const sectionFieldsValidation = await sectionKeysValidator();

 if (sectionFieldsValidation) {
    const questionAssignmentValidation = await questionAssignValidator();
    return questionAssignmentValidation;
 } else {
    return false;
 }

}

export default ExamFormValidator;