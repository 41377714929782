import React, { useState, useEffect, useContext } from 'react';
import { Scatter } from 'react-chartjs-2';
import axios from 'axios';
import Context from '../../user_details';

const ToppersScatterPlot = () => {
    const { user } = useContext(Context);
    const [numberOfExams, setNumberOfExams] = useState(20);
    const [examData, setExamData] = useState([]);
    const [firmsList, setFirmsList] = useState([]);
    const [selectedClient, setSelectedClient] = useState(user.user_type === 'superAdmin'? '' : user.firm_id);

    useEffect(() => {
        const fetchData = async (numExams) => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard/getToppersOfLastExams/${selectedClient}?numberOfExams=${numExams}`);
                setExamData(response.data);
            } catch (error) {
                console.error('Error fetching exam data:', error);
            }
        };
        if(selectedClient && numberOfExams) fetchData(numberOfExams);
    }, [numberOfExams, selectedClient]);

    useEffect(() => {
        const fetchFirmsList = async() => {
          try{
            const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/ums/firms/list`);
            if(!data.success) throw new Error('Unable to fetch firms List');
            setFirmsList(data.results);
          } catch(e) {
            alert("Unable to fectch firms list");
            console.error(e);
          }
        }
        if(user.user_type === 'superAdmin') {
          fetchFirmsList();
        }
      }, []);
    

    const handleClientChange = (e) => {
        setSelectedClient(e.target.value);
    }

    const handleDropdownChange = (e) => {
        setNumberOfExams(parseInt(e.target.value, 10));
    };

    //rgba(54, 162, 235, ${0.6 - (index * 0.05)})
    const chartData = {
        datasets: examData.map((item, index) => ({
            label: item.examName,
            data: item.name.map((name, i) => ({ x: index, y: item.topScore, r: 5 + (i * 2) })), // Adjust radius for multiple toppers
            backgroundColor: `rgba(155, ${255 - index * 20}, 192, 0.9)`,
            borderColor: `rgba(${75 + index * 30}, ${192 - index * 20}, 192, 1)`,
            borderWidth: 1,
            pointStyle: 'circle',
            pointRadius: 8,
            pointHoverRadius: 10,
        })),
    };

    const options = {
        scales: {
            x: {
                type: 'linear',
                position: 'bottom',
                title: {
                    display: true,
                    text: 'Exam Index',
                    color: '#555',
                    font: {
                        size: 14,
                        weight: 'bold',
                    },
                },
                grid: {
                    color: '#e5e5e5',
                    lineWidth: 1,
                },
                ticks: {
                    display: false, // Hide x-axis labels
                },
            },
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'Top Score',
                    color: '#555',
                    font: {
                        size: 14,
                        weight: 'bold',
                    },
                },
                grid: {
                    color: '#e5e5e5',
                    lineWidth: 1,
                },
            },
        },
        plugins: {
            tooltip: {
                backgroundColor: '#ffffff',
                titleColor: '#333',
                bodyColor: '#333',
                borderColor: '#ddd',
                borderWidth: 1,
                callbacks: {
                    label: (context) => {
                        const examIndex = context.dataIndex;
                        const examName = examData[examIndex]?.examName || 'N/A';
                        const topperNames = examData[examIndex]?.name.join(', ') || '';
                        return `Exam: ${examName}, Score: ${context.raw.y}, Toppers: ${topperNames}`;
                    },
                },
            },
            legend: {
                display: false,
            },
        },
        elements: {
            point: {
                borderWidth: 2,
                hoverBackgroundColor: 'rgba(75,192,192,0.8)',
                hoverBorderWidth: 3,
            },
        },
    };

   return (
       <div className="chart-sub-container">
            <h3>Exam Vs Toppers</h3>
            <div className='d-flex gap-2 align-items-center justify-content-end'>
                {user.user_type === 'superAdmin' && 
                    <div className='form-elements-container'>
                        <div className='form-info-entry-area'>
                            <select  value={selectedClient} onChange={handleClientChange} className='form-select'>
                                <option value='' disabled>Select Client</option>
                            {firmsList.map(firm => (
                                <option key={firm._id} value={firm._id}>{firm.firm_name}</option>
                            ))}
                            </select>
                        </div>
                    </div>
                }
            
                <div className='form-elements-container row float-end'>
                    <div className='form-info-entry-area'>
                        <select id="numberOfExams" value={numberOfExams} onChange={handleDropdownChange} className='form-select'>
                            <option value={5}>Last 5 Exams</option>
                            <option value={10}>Last 10 Exams</option>
                            <option value={20}>Last 20 Exams</option>
                            <option value={40}>Last 40 Exams</option>
                            <option value={100}>Last 100 Exams</option>
                        </select>
                    </div>
                </div>
            </div>
            {selectedClient?
                Object.keys(chartData).length > 0 ?
                    <Scatter data={chartData} options={options} />
                    :
                    <p className='nexus-color-primary-note text-center fw-medium'>Something Went Wrong. Please Try again later</p>
            :
            <div className='d-flex align-items-center justify-content-center h-100 w-100'>
                <p className='nexus-color-primary-note text-center m-5'>Please Select an Organisation</p>
            </div>
            }
       </div>
     );
};

export default ToppersScatterPlot;
