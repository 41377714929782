import React, {useContext,useState} from 'react';
import axios from 'axios';
import Context from '../../user_details';

const AssignQuestionsModal = ({sectionValues, selectedSection, setSelectionQuestions, selectedQuestions, currentComplexity, setSectionValues, questionsLoader, isexamPatternSelected, availableQuestions,questionsAvailabilityPerPick, showAssignModal, setShowAssignModal}) => {
    
    const [complexityLabels,setComplexityLabel] = useState({1:"easy", 2: "medium", 3: 'hard'}); 
    console.log("assignquestions model here==<",currentComplexity, questionsAvailabilityPerPick)
    const SaveChanges = () => {
        let questionType = sectionValues[selectedSection - 1].question_types[0].question_type;
        let temporary = sectionValues;
        if (questionType === 1 || questionType === 2 ) {
          temporary[selectedSection - 1].assignedQuestions[0][questionType][currentComplexity].question_ids = selectedQuestions;
        }
        else {
          temporary[selectedSection - 1].assignedQuestions[0][questionType].question_ids = selectedQuestions;
        }
        setSectionValues([...temporary]);
        setShowAssignModal(false);
    }

    const checkQuestionIsChecked = (questionId) => {
        let questionType = sectionValues[selectedSection - 1]?.question_types[0]?.question_type;
        let assignedQuestions = sectionValues[selectedSection - 1]?.assignedQuestions[0];
        console.log(`${questionId} these are assigned questions with complexity ${currentComplexity} and type ${questionType}`, assignedQuestions, questionType, );
        if (questionType == 1 || questionType == 2 || questionType == 3) {
          const isChecked =  assignedQuestions[questionType][currentComplexity]?.question_ids.indexOf(questionId) > -1;
          console.log("these are assigned questions returning", isChecked, questionId)
          return isChecked;
        }
        else {
          return assignedQuestions[questionType]?.question_ids?.indexOf(questionId) > -1;
        }
    };

    const changeAssignedQuestions = (event) => {
        let questionType = sectionValues[selectedSection - 1]?.question_types[0]?.question_type;
        let assignedQuestions = sectionValues[selectedSection - 1]?.assignedQuestions[0];
        if (questionType == 1 || questionType === 2 || questionType == 3) {
          assignedQuestions = assignedQuestions[questionType][currentComplexity]?.question_ids;
        }
        else {
          assignedQuestions = assignedQuestions[questionType].question_ids;
        }
        if (event.target.checked) {
          assignedQuestions.push(event.target.value);
        }
        else {
          assignedQuestions.splice(assignedQuestions.indexOf(event.target.value), 1);
        }
        setSelectionQuestions(assignedQuestions);
      }


    return (
        <div className="modal fade assign-modal assign-questions-container" data-bs-backdrop="static" data-bs-keyboard="false" id="assignmodel" tabIndex="-1" role="dialog" aria-labelledby="assignmodelTitle" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered assign-modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header justify-content-between">
                    <h5 className="modal-title " id="exampleModalLongTitle">Assign Questions</h5>
                  </div>
                  <div className="modal-body assign-modal-body">
                    {showAssignModal && 
                     !questionsLoader && isexamPatternSelected ?
                      questionsAvailabilityPerPick[complexityLabels[currentComplexity]]?.length > 0 ?
                        <div className='h-100 w-100 d-flex flex-column align-items-stretch custom-scrollbar-styles' style={{ overflowY: 'auto', overflowX: 'hidden' }}>
                          <div className='row'>
                            { questionsAvailabilityPerPick[complexityLabels[currentComplexity]].map(question => {
                              return (
                                <div className='col-md-12' style={{backgroundColor:'#fff', marginBottom:'10px'}}>
                                  <div className='assign_questions-item border p-3'>
                                    <label className='select-question-item'>
                                      <input type="checkbox" className='questions-checkbox' value={question._id} defaultChecked={checkQuestionIsChecked(question._id)} onChange={(e) => { changeAssignedQuestions(e) }} />
                                      <div className='question-content' dangerouslySetInnerHTML={{ __html: question.question }}></div>
                                    </label>
                                    <div className='answers-option-container'>
                                      {question.answers.map((answer, index) => {
                                        return <p key={index} className={answer.is_correct ? "answers-option text-success" : "answers-option text-danger"} >{index + 1}. &nbsp;{answer.answer} </p>
                                      }

                                      )}
                                    </div>
                                  </div>
                                </div>
                              )
                            })}
                          </div>
                        </div>
                        : "Questions not available" : <div className="spinner-border text-primary" role="status">
                        <span className="sr-only"></span>
                      </div>
                    }
                  </div>
                  <div className="modal-footer align-items-center justify-content-center">
                    <button type="button" className="btn app-main-form-btn-only-brdr" data-bs-dismiss="modal">Close</button>
                    <button type="button" className="btn app-main-form-btn" data-bs-dismiss="modal" onClick={SaveChanges}>Save changes</button>
                  </div>
                </div>
              </div>
            </div>
    );
}

export default AssignQuestionsModal;