import React, { useState, useContext, useEffect } from "react";
import Context from '../../user_details';
import axios from 'axios';

const ExamSearchForm = ({ searchParams, handleSearchInputChange, getExamsListBySearch, resetSearch }) => {
    const { user } = useContext(Context);
    const [clientsUms, setClientsUms] = useState([]);
    const [courses, setCourses] = useState([]);
    const [examPatterns, setExamPatterns] = useState([]);

    useEffect(() => {
        const fetchExamPatterns = async () => {
            try {
                const firmId = user.user_type === 'superAdmin' && searchParams.client_id 
                    ? searchParams.client_id 
                    : user.firm_id;
                const response = await axios.get(process.env.REACT_APP_API_URL + `/examPattern/list/${firmId}`);
                setExamPatterns([...response.data.examPatterns]);
            } catch (error) {
                console.error('Error fetching exam patterns:', error);
            }
        };
        fetchExamPatterns();
    }, [user.firm_id, searchParams.client_id, user.user_type]);

    useEffect(() => {
        const fetchClients = async () => {
            if (user.user_type === 'superAdmin') {
                try {
                    const response = await axios.get(`${process.env.REACT_APP_API_URL}/ums/firms/list`);
                    if (response && response.data && response.data.success) {
                        setClientsUms(response.data.results || []);
                    }
                } catch (error) {
                    console.error('Error fetching clients:', error);
                }
            }
        };
        fetchClients();
    }, [user.user_type]);

    useEffect(() => {
        const fetchCourses = async () => {
            const firmId = user.user_type === 'superAdmin' && searchParams.client_id 
                ? searchParams.client_id 
                : user.firm_id;

            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/courses/list/${firmId}`);
                if (response.data && response.data.result && response.data.result.courses) {
                    setCourses(response.data.result.courses);
                }
            } catch (error) {
                console.error('Error fetching courses:', error);
            }
        };

        fetchCourses();
    }, [user.firm_id, searchParams.client_id, user.user_type]);

    return (
        <div className="general-add-form-container mt-3">
            <form onSubmit={getExamsListBySearch}>
                <div className="row">
                    <div className="col-md-4">
                        <input
                            type="text"
                            name="examName"
                            placeholder="Exam Name"
                            className="form-control"
                            value={searchParams.examName}
                            onChange={handleSearchInputChange}
                        />
                    </div>
                    {user.user_type === 'superAdmin' && (
                        <div className="col-md-4">
                            <select
                                name="client_id"
                                className="form-control"
                                value={searchParams.client_id}
                                onChange={handleSearchInputChange}
                            >
                                <option value="">Select Client</option>
                                {clientsUms.map(client => (
                                    <option key={client._id} value={client._id}>
                                        {client.firm_name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    )}
                    <div className="col-md-4">
                        <select
                            name="selectedCourse"
                            className="form-control"
                            value={searchParams.selectedCourse}
                            onChange={handleSearchInputChange}
                        >
                            <option value="">Select Course</option>
                            {courses.map(course => (
                                <option key={course._id} value={course._id}>
                                    {course.course_name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-md-4 mt-3">
                    
                        <select
                            name="selectedExamPattern"
                            className="form-control"
                            value={searchParams.selectedExamPattern}
                            onChange={handleSearchInputChange}
                        >
                            <option value="">Select Exam Pattern</option>
                            {examPatterns.map(pattern => (
                                <option key={pattern._id} value={pattern._id}>
                                    {pattern.exam_pattern_name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-md-4 mt-3">
                        <input
                            type="date"
                            name="startDate"
                            className="form-control"
                            value={searchParams.startDate}
                            onChange={handleSearchInputChange}
                        />
                    </div>
                    <div className="col-md-4 mt-3">
                        <input
                            type="date"
                            name="endDate"
                            className="form-control"
                            value={searchParams.endDate}
                            onChange={handleSearchInputChange}
                        />
                    </div>
                    <div className="col-md-12 mt-3 text-center">
                        <button type="submit" className="app-btn-primary">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                            </svg>
                            &nbsp;Search
                        </button>
                        <button type="button" className="cancel-form-btn" onClick={resetSearch}>Reset</button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default ExamSearchForm;
