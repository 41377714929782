// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-title {
  position: absolute;
  top: -1.25rem;
}
.form-label {
  font-size: 15px;
  font-weight: 500;
}
.form-input:disabled {
  color: rgba(10, 2, 2, 0.566);
  font-style: oblique;
  font-weight: 700;
  border: none;
  padding: 0px;
}
.form-control-disabled {
  color: rgba(10, 2, 2, 0.566);
  font-style: oblique;
  font-weight: 700;
  border: none;
  padding: 0px;
}

.edit-pic {
  position: absolute;
  bottom: -1px;
  right: 0px;
}
.edit-pic:hover {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/MyProfile/myprofile.scss"],"names":[],"mappings":"AACI;EACI,kBAAA;EACA,aAAA;AAAR;AAEI;EACI,eAAA;EACA,gBAAA;AAAR;AAGQ;EAGI,4BAAA;EACA,mBAAA;EACA,gBAAA;EACA,YAAA;EACA,YAAA;AAHZ;AAQQ;EACI,4BAAA;EACA,mBAAA;EACA,gBAAA;EACA,YAAA;EACA,YAAA;AANZ;;AAWA;EACI,kBAAA;EACA,YAAA;EACA,UAAA;AARJ;AASI;EACI,eAAA;AAPR","sourcesContent":[".form {\n    &-title {\n        position: absolute;\n        top: -1.25rem;\n    }\n    &-label {\n        font-size: 15px;\n        font-weight: 500;\n    }\n    &-input {\n        &:disabled {\n            // border: 1px solid grey;\n            // background-color: white !important;\n            color: rgba(10, 2, 2, 0.566);\n            font-style: oblique;\n            font-weight: 700;\n            border: none;\n            padding: 0px;\n        }\n    }\n\n    &-control{\n        &-disabled {\n            color: rgba(10, 2, 2, 0.566);\n            font-style: oblique;\n            font-weight: 700;\n            border: none;\n            padding: 0px;\n        }\n    }\n\n}\n.edit-pic {\n    position: absolute;\n    bottom: -1px;\n    right: 0px;\n    &:hover {\n        cursor:pointer;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
