import React, { useState, useEffect, useContext , createContext } from "react";
import axios from "axios";
import Context from '../../user_details';
import ReactQuill, {Quill} from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import DOMPurify from 'dompurify';
import './questionForm.css'
import AnswerOptions from './AnswerOptions';
import { playSound } from '../../helpers/otherSounds';
// import CKEditor from './ckEditor';
import InjectCKEditor from "./ckEditor/ckEditor";




export default function QuestionForm({ onQuestionAdded, SelectedQuestion , cancelBtnHandler }) {
  const context = useContext(Context);
  const [questionObj, setQuestionObj] = useState({
    question: "",
    complexity: "",
    marks: "",
    questionType: "",
    answers: [{ answer: "", is_correct: false , is_deleted : false }],
    selectedClient: SelectedQuestion? SelectedQuestion.client_id : context.user.user_type === "superAdmin" ? "" : context.user.firm_id,
    selectedCourse: "",
    selectedSubject: "",
    selectedTopic: "",
  });
  
  const [errorMessage, setErrorMessage] = useState("");
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');

  const [courses, setCourses] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [topics, setTopics] = useState([]);
  const [clients, setClients] = useState([]);
  const [formType,setFormType] = useState('Add');
  const [selectedFile, setSelectedFile] = useState(null);
  const [showFileUpload, setShowFileUpload] = useState(false);
  const [showFormContent, setShowFormContent] = useState(true);
  const [isPreview, setIsPreview] = useState(false);
  const [editorLoader, setEditorLoader] = useState(true);

  const ckEditorEmptyValue = '<p><br data-cke-filler="true"></p>';

  useEffect(() => {
	console.log("this is question object", questionObj, SelectedQuestion)
  }, [questionObj, SelectedQuestion])

  const quillModules = {
    toolbar: [
      [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
      [{size: []}],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      ['link', 'image'],
      ['code'],
      ['clean'],
    ],
    clipboard: {
      matchVisual: false,
    }
  };

  const quillFormats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
  ];

  const togglePreview = () => {
    setIsPreview(!isPreview);
  };

  const stripPTags = (html) => {
		let sanitizedHtml = DOMPurify.sanitize(html);
		sanitizedHtml = sanitizedHtml.replace(/<\/?p[^>]*>/g, "");
		return sanitizedHtml;
	};

  useEffect(() => {
    if (SelectedQuestion) {
      setQuestionObj({
        ...SelectedQuestion,
        selectedClient : SelectedQuestion?.client_id,
        selectedCourse: SelectedQuestion?.course_id?._id,
        selectedSubject: SelectedQuestion?.subject_id?._id,
        selectedTopic: SelectedQuestion?.topic_id?._id,
        questionType: SelectedQuestion?.question_type,
      });
      setFormType('Update');
      if(SelectedQuestion?.client_id?._id) fetchClients();
      if (SelectedQuestion?.course_id?._id) fetchSubjects(SelectedQuestion?.course_id?._id);
      if (SelectedQuestion?.subject_id?._id) fetchTopics(SelectedQuestion?.subject_id?._id);
    }
  }, [SelectedQuestion]);

  useEffect(() => {
    if(context.user.user_type === "superAdmin") {
      fetchClients();
    }
  }, []);

  const fetchClients = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/ums/firms/list`);
      if (response.data.success && response.data.results.length) {
        setClients(response.data.results);
      } else {
		playSound('ERROR');
        setMessage("Clients data not available. You may need to create Clients.");
		setMessageType('error');
      }
    } catch (error) {
      console.error("Error fetching clients:", error);
    }
  };

  useEffect(() => {
    fetchCourses();
  }, []);

  const fetchCourses = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/courses/list/${context.user.firm_id}`);
      if(response.data && response.data.result.courses) {
        setCourses(response.data.result.courses);
      }
    } catch (error) {
      console.error('Error fetching courses:', error);
    }
  };

  const fetchSubjects = async (courseId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/subjects/list/${courseId}`);
      setSubjects(response.data);
    } catch (error) {
      console.error("Error fetching subjects:", error);
    }
  };

  const fetchTopics = async (subjectId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/topics/list/${subjectId}`);
      console.log(response);
      console.log(response.data);
      setTopics(response.data.result);
    } catch (error) {
      console.error("Error fetching topics:", error);
    }
  };

  	const handleClientChange = async (e) => {
		var clientId = e.target.value;
		setQuestionObj((prevState) => {
			return {
				...prevState,
				selectedClient: clientId,
				selectedCourse: "",
				selectedSubject: "",
				selectedTopic: "",
			};
		});
		// Fetch courses based on the selected client
		try {
			const response = await axios.get(`${process.env.REACT_APP_API_URL}/courses/clientId/${clientId}`);
			if (response.data && response.data.courses && response.data.courses.length > 0) {
				setCourses(response.data.courses);
				setMessage("");
			} else if(response.data && response.data.result && response.data.result.courses && (response.data.result.courses).length > 0){
				setCourses(response.data.result.courses);
				setMessage("");
			} else {
				setCourses([]);
				playSound('ERROR');
				setMessage("Courses data not available for the selected Client. You may need to create Courses for the selected Client.");
				setMessageType('error');
			}
		} catch (error) {
			console.error("Error fetching courses:", error);
		}
	};
	
	const handleCourseChange = (e) => {
		const courseId = e.target.value;
		setQuestionObj((prevState) => {
			return {
				...prevState,
				selectedCourse: courseId,
				selectedSubject: "",
				selectedTopic: "",
			};
		});
		fetchSubjects(courseId);
	};

	const handleSubjectChange = (e) => {
		const subjectId = e.target.value;
		setQuestionObj((prevState) => {
			return {
				...prevState,
				selectedSubject: subjectId,
				selectedTopic: "",
			};
		});
		fetchTopics(subjectId);
	};

	const handleAnswerChange = (index, newValue, name) => {
		setQuestionObj((prevState) => {
			let updatedAns = [...prevState.answers];
			// if (prevState.questionType == "1"  || prevState.questionType == "2")
			if (name === "is_correct" && (prevState.questionType == "1" || prevState.questionType == "2")){
        		updatedAns = updatedAns.map((d,i) => ({
						...d,
						is_correct: i === index ? newValue : false,
					}));
				
			}
			updatedAns[index][name] = newValue;
			return {
				...prevState,
				answers: updatedAns,
			};
		});
	};

	const addAnswer = () => {
		setQuestionObj((prevState) => {
			return {
				...prevState,
				answers: [...prevState.answers, { answer: "", is_correct: false, is_deleted: false }],
			};
		});
	};

	const removeAnswer = (index) => {
		setQuestionObj((prevState) => {
			let updatedAns = [...prevState.answers];
			updatedAns[index]["is_deleted"] = true;
			return {
				...prevState,
				answers: updatedAns,
			};
		});
	};

	const validateTrueFalseSelection = () => {
		if (questionObj.questionType == "1" || questionObj.questionType == "2") {
			const istrueorFalseSelected = questionObj.answers.some(answer => answer.is_correct);
			if (!istrueorFalseSelected) {
				playSound('NOTICE');
				alert("Please select Atleast One Correct Answer.");
				return false;
			}
		}
		return true;
	};

	const extractQuestionValue = async() => {
		var questionHTML = document.getElementsByClassName('ck-editor__editable')[0].innerHTML;
		var tempDiv = document.createElement('div');
		tempDiv.innerHTML = questionHTML;

		// Remove elements with class 'ck-reset_all' (this is causing black images in the end of question by default)
		var elementsToRemove = tempDiv.getElementsByClassName('ck-reset_all');
		while (elementsToRemove.length > 0) {
			elementsToRemove[0].parentNode.removeChild(elementsToRemove[0]);
		}

		questionHTML = tempDiv.innerHTML;

		return questionHTML;
	}

	const handleSubmit = async (event) => {
		event.preventDefault();
		
		// alert(`${questionHTML}, ${questionHTML==ckEditorEmptyValue}`)
		if (!validateTrueFalseSelection()) return;

		const questionHTML = await extractQuestionValue();
    	if(questionObj.question == undefined || questionObj.question == "") {
			playSound('ERROR');
			setMessage('Please enter Question content.');
			setMessageType('error');
			return false;
		}
		// setQuestionObj(prev => ({...prev, question: questionHTML}))
    
		try {
			const response = await axios.post(`${process.env.REACT_APP_API_URL}/question/create`, {
				question: questionObj.question,
				complexity: questionObj.complexity,
				marks: questionObj.marks,
				question_type: questionObj.questionType,
				client_id: questionObj.selectedClient,
				course_id: questionObj.selectedCourse,
				subject_id: questionObj.selectedSubject,
				topic_id: questionObj.selectedTopic,
				answers: questionObj.answers.map((answer) => ({
					answer: answer.answer,
					is_correct: answer.is_correct,
					is_deleted: answer.is_deleted,
				})),
			});
			console.log("New question added:", response.data);
			setQuestionObj({
				question: "",
				complexity: "",
				marks: "",
				questionType: "",
				answers: [{ answer: "", is_correct: false, is_deleted: false }],
				selectedCourse: "",
				selectedSubject: "",
				selectedTopic: "",
			
			});
			playSound('SUCCESS');
			setMessage('Question added successfully!');
			setMessageType('success');
			setTimeout(onQuestionAdded(), 2000);
		} catch (error) {
			playSound('ERROR');
			console.error("Error adding question:", error);
			setMessage("Failed to add question. Please try again.");
			setMessageType('error');
		}
	};

	const updateHandler = async (event) => {
		event.preventDefault();
		if (!validateTrueFalseSelection()) return;
		// const questionHTML = await extractQuestionValue();
    	if(questionObj.question == undefined || questionObj.question == "") {
			playSound('ERROR');
			setMessage('Please enter Question content.');
			setMessageType('error');
			return false;
		}
		// setQuestionObj(prev => ({...prev, question: questionHTML}))
		try {
			const response = await axios.patch(`${process.env.REACT_APP_API_URL}/question/update/${questionObj._id}`, {
				selectedCourse: questionObj.selectedCourse,
				selectedSubject: questionObj.selectedSubject,
				selectedTopic: questionObj.selectedTopic,
				question: questionObj.question,
				complexity: questionObj.complexity,
				marks: questionObj.marks,
				question_type: questionObj.questionType, // This is where the question type is being sent for update
				course_id: questionObj.selectedCourse,
				subject_id: questionObj.selectedSubject,
				topic_id: questionObj.selectedTopic,
				answers: questionObj.answers.map((answer) => ({
				answer: answer.answer,
				is_correct: answer.is_correct,
				question_id: questionObj._id,
				_id: answer._id,
				is_deleted: answer.is_deleted,
				})),
				deletedAnswers : (questionObj.deletedAnswers) ? questionObj.deletedAnswers.map(d => d._id) : []
			});
			console.log("question updated:", response.data);
			// Clear form fields after successful submission
			setQuestionObj({
				question: "",
				complexity: "",
				marks: "",
				questionType: "", // You're resetting questionType here, which might be causing the issue
				answers: [{ answer: "", is_correct: false, is_deleted: false }],
				selectedCourse: "",
				selectedSubject: "",
				selectedTopic: "",
			});
			
			playSound('SUCCESS');
			setMessage('Question updated successfully!');
			setMessageType('success');
			setTimeout(function(){onQuestionAdded()}, 2000);
		} catch (error) {
			playSound('ERROR');
			console.error("Error Update question:", error);
			setMessage("Failed to Updated question. Please try again.");
			setMessageType('error');
		}
    };

	const questionTypeChangeHandler = (e) => {
		setQuestionObj((prevState) => {                  
			let prevAnsListToDelete = [];
			let newAnsList = [];
			 if (e.target.value == "2") {			   
			   newAnsList = [
				 { answer: "True", is_correct: false, is_deleted: false },
				 { answer: "False", is_correct: false, is_deleted: false },
			   ];
			 } else {			   
			   newAnsList = [{ answer: "", is_correct: false, is_deleted: false }];
			 }
			 var existingRecords = prevState.answers.filter(a => a.created || a.created != null);
			 if(existingRecords.length > 0){
				prevAnsListToDelete = existingRecords.map(d => { 
					return {
						...d,
                        questionType : prevState.questionType
					}
				});
			 }			 
	         return {
				...prevState,                              
				questionType: e.target.value,
				answers: newAnsList,
				deletedAnswers :  prevState.deletedAnswers?.length > 0 ? prevState.deletedAnswers : prevAnsListToDelete
			};
		})
	};
	const handleQuestionChange = (value) => {
		setQuestionObj((prevState) => {
		  return {
			...prevState,
			question: value,
		  };
		});
	  };

  	const PreviewTrueSVG = (<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16">
							<path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325"/>
						</svg>)
	const PreviewFalseSVG = (<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
								<path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z"/>
								<path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0"/>
							</svg>) 

	

  return (
		<div className="add-questions-form general-add-form-container add-question-form-container">
			
			<h3 className="mar-L-25">{formType} Question</h3>
			
						
			{showFormContent && (
				<div className="">
				<form onSubmit={handleSubmit} className="d-flex flex-column w-100">
					<div className="d-flex align-items-stretch gap-3 " >
						{/* Question details */}
						<div className="w-50">
							{context.user.user_type === "superAdmin" && (
								<div className="mb-3 mt-3 ms-3 row">
									{/* Client details */}
									<div className="col-md-12">
										<label htmlFor="client" className="form-label">
											Select Client:
										</label>
										<select id="client" className="form-select" value={questionObj.selectedClient} onChange={handleClientChange} required disabled={SelectedQuestion}>
											<option value="">Select Client</option>
											{clients.map((client) => (
												<option key={client._id} value={client._id}>
													{client.firm_name}
												</option>
											))}
										</select>
									</div>
								</div>
							)}
							<div className="mb-3 ms-3 row">
								{/* Course details */}
								<div className="col-md-6">
									<label htmlFor="course" className="form-label">
										Select Course:
									</label>
									<select id="course" className="form-select" value={questionObj.selectedCourse} onChange={handleCourseChange} required>
										<option value="">Select Course</option>
										{Array.isArray(courses) &&
											courses.map((course) => (
												<option key={course._id} value={course._id}>
													{course.course_name}
												</option>
											))}
									</select>
								</div>

								{/* Subject details */}
								<div className="col-md-6">
									<label htmlFor="subject" className="form-label">
										Select Subject:
									</label>
									<select id="subject" className="form-select" value={questionObj.selectedSubject} onChange={handleSubjectChange} required>
										<option value="">Select Subject</option>
										{Array.isArray(subjects) &&
											subjects.map((subject) => (
												<option key={subject._id} value={subject._id}>
													{subject.subject_name}
												</option>
											))}
									</select>
								</div>
							</div>

							<div className="mb-3 ms-3 row">
								{/* Topic details */}
								<div className="col-md-6">
									<label htmlFor="topic" className="form-label">
										Select Topic:
									</label>
									<select
										id="topic"
										className="form-select"
										value={questionObj.selectedTopic}
										onChange={(e) =>
											setQuestionObj((prevState) => ({
												...prevState,
												selectedTopic: e.target.value,
											}))
										}
										required>
										<option value="">Select Topic</option>
										{Array.isArray(topics) &&
											topics.map((topic) => (
												<option key={topic._id} value={topic._id}>
													{topic.topic_name}
												</option>
											))}
									</select>
								</div>

								{/* Complexity details */}
								<div className="col-md-6">
									<label htmlFor="complexity" className="form-label">
										Complexity:
									</label>
									<select
										id="complexity"
										className="form-select"
										value={questionObj.complexity}
										onChange={(e) => {
											const complexityValue = e.target.value;
											setQuestionObj((prevState) => {
												let marks = 0;
												switch (complexityValue) {
													case "1":
														marks = 1;
														break;
													case "2":
														marks = 2;
														break;
													case "3":
														marks = 4;
														break;
													default:
														marks = 0;
														break;
												}
												return { ...prevState, complexity: complexityValue, marks: marks };
											});
										}}
										required
									>
										<option value="">Select Complexity</option>
										<option value="1">Easy</option>
										<option value="2">Medium</option>
										<option value="3">Difficult</option>
									</select>
								</div>
							</div>
							<div className="mb-3 ms-3 row">
								{/* Marks details */}
								<div className="col-md-6">
									<label htmlFor="marks" className="form-label">
										Marks:
									</label>
									<input
										id="marks"
										type="number"
										className="form-control"
										value={questionObj.marks}
										readOnly 
										onChange={(e) =>
											setQuestionObj((prevState) => {
												return { ...prevState, marks: e.target.value };
											})
										}
										required
									/>
								</div>
								{/* 'Question Type' details */}
								
								<div className="col-md-6">
									<label htmlFor="questionType" className="form-label">
										Question Type:
									</label>
									<select
										id="questionType"
										className="form-select"
										value={questionObj.questionType}
										onChange={(e) =>  questionTypeChangeHandler(e)}
										required>
										<option value="">Select Question Type</option>
										<option value="1">Multiple Choice</option>
										<option value="2">True / False</option>
										<option value="3">Check Boxes</option>
										<option value="4" disabled>
											Comprehensive
										</option>
										<option value="5" disabled>
											File Upload
										</option>
									</select>
								</div>
							</div>
						</div>
						{/* Question editor container */}
						<div className="w-50 question-editorContainer">
						<div className="d-flex flex-column align-items-center gap-1 p-3 h-100">
									<div className="w-100 d-flex justify-content-between ">
										<label htmlFor="question" className="form-label">Question:</label>
									</div>
									{/* Quill and preview form  */}
									 <div className="w-100 h-100"  >
										<div  id="cke5-mathtype-demo">
											{InjectCKEditor(SelectedQuestion?.question, setQuestionObj)}
										</div>
									</div>                       
								</div>
							
						</div>
						
					</div>
					{/* Anwers options section */}
					<div className="row">
									{/* =========  IS Correct ANSWER Code Starts here ============= */}
							<div className="col-6"></div>
							<div className="col-6 p-3">
								<AnswerOptions 
								questionObj={questionObj}
								handleAnswerChange={handleAnswerChange}
								addAnswer={addAnswer}
								removeAnswer={removeAnswer}
								
								/>
								
							</div>
									{/* =========  IS Correct ANSWER Code Ends here ============= */}
					</div>
					{/* Submit button section */}
					<div className="col-md-12">
						<div className="add-cancel-btn-container">
							{formType === "Add" ? (
								<button type="submit" className="add-form-btn">
									{formType} Question
								</button>
							) : (
								<button onClick={updateHandler} className="add-form-btn">
									{formType} Question
								</button>
							)}

							<button type="button" className="cancel-form-btn" onClick={cancelBtnHandler}>
								Cancel
							</button>
						</div>
					</div>
				</form>
				{message && (
					<div className={`message ${messageType}`}>
					{message}
					</div>
				)}
			</div>
			)}
		</div>
		// =========  Edited Code Ends here =============
	);
}
