// AnswerOptions.js
import React from "react";

export default function AnswerOptions({ questionObj, handleAnswerChange, addAnswer, removeAnswer }) {
  return (
    <div className="mb-3 col-md-12">
        <p>
            <label className="form-label">Answers:</label>
            {questionObj.questionType != "2" && (
                <button type="button" className="btn btn-success float-end add-answers-options" onClick={addAnswer}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-plus-square-fill"
                        viewBox="0 0 16 16">
                        <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5.0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0" />
                    </svg>
                    <span>Add Answer</span>
                </button>
            )}
        </p>
        {questionObj.answers
            .filter((d) => !d.is_deleted)
            .map((answer, index) => (
                <div key={index} className="input-group mb-3">
                    {questionObj.questionType == "2"? (
                        <>
                            <input type="text"
                                className="form-control"
                                value={answer.answer} disabled />
                            <div className="input-group-text is-correct-answer-container">
                                <input
                                    type="radio"
                                    className="form-check-input is_correct_radio"
                                    checked={answer.is_correct}
                                    onChange={(e) => handleAnswerChange(index, e.target.checked, "is_correct")}
                                />
                                <label className="form-check-label">Is Correct Answer</label>
                            </div>
                        </>
                    ) : (
                        <>
                            <input
                                type="text"
                                className="form-control"
                                value={answer.answer}
                                onChange={(e) => handleAnswerChange(index, e.target.value, "answer")}
                                required
                                disabled={questionObj.questionType == "2"}
                            />
                            <div className="input-group-text is-correct-answer-container">
                                <input
                                    type={questionObj.questionType == "1" ? "radio" : "checkbox"}
                                    className="form-check-input is_correct_radio"
                                    checked={answer.is_correct}
                                    onChange={(e) => handleAnswerChange(index, e.target.checked, "is_correct")}
                                />
                                <label className="form-check-label">Is Correct Answer</label>
                            </div>
                            <div className="col-md-1 delete-btn-container">
                                {index !== 0 && (
                                    <div >
                                        <button type="button" className="btn btn-danger delete-answers-options" onClick={() => removeAnswer(index)}>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                class="bi bi-trash-fill"
                                                viewBox="0 0 16 16">
                                                <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5M8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5m3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0" />
                                            </svg>
                                        </button>
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                </div>
            ))}
    </div>
  );
}
