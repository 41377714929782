import React, { useContext, useState, useEffect } from "react";
import Context from '../../user_details';
import './myprofile.scss';
import edit_icon from '../../assets/edit_icon.svg';
import cancel_icon from '../../assets/close_icon.svg';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Offcanvas from 'react-bootstrap/Offcanvas';
import axios from "axios";

const MyProfile = () => {
    const { user } = useContext(Context); // Use your actual context
    const [updatedUser, setUpdatedUser] = useState({
        first_name: user.first_name,
        last_name: user.last_name,
        contact_number: user.contact_number,
        profile_image: user.profile_image,
        alternate_email: user.alternate_email,
        email: user.email
    });
    const [profilePicPreview, setProfilePicPreview] = useState(user.profile_image);
    const [editMode, setEditMode] = useState(false);
    const [showCanvas, setShowCanvas] = useState(false);
    const [canvasMessage, setCanvasMessage] = useState(undefined);


    console.log(useContext(Context), " this is user details")

    useEffect(() => {
        setInitialValues();
    }, []);

    useEffect(() => {
        console.log(updatedUser, "this is updated")
        console.log(updatedUser.profile_image == undefined, " this is updated profile image")
    }, [updatedUser])

    const setInitialValues = () => {
        setUpdatedUser({
            first_name: user.first_name,
            last_name: user.last_name,
            contact_number: user.contact_number,
            profile_image: user.profile_image,
            alternate_email: user.alternate_email,
            email: user.email
        })
        setProfilePicPreview(user.profile_image);
    }

    const successCanvasMessage = (<div className="h-100 w-100 d-flex align-items-center justify-content-center p-3 text-success text-center ">✔ Your Profile has been updated</div>)
    const FailCanvasMessage = (<div className="h-100 w-100 d-flex align-items-center justify-content-center p-3 text-danger text-center ">X Something went wrong</div>)
    // Function to get initials from the first and last name
    const getInitials = () => {
        const { first_name, last_name } = updatedUser;
        return `${first_name?.charAt(0) || ''}${last_name?.charAt(0) || ''}`.toUpperCase();
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUpdatedUser({ ...updatedUser, [name]: value });
    };

    const handleProfilePicChange = async (e) => {
        try {
            const file = e.target.files[0];
            const formData = new FormData();
            formData.append('profileImage', file);
            // if (file) {
            //     const reader = new FileReader();
            //     reader.readAsDataURL(file); 

            //     reader.onloadend = async() => {
            //         const base64String = reader.result;
            //         setProfilePicPreview(base64String); 
            //         setUpdatedUser({ ...updatedUser, profile_image: base64String });
            //         const {data: response} = await axios.post(`${process.env.REACT_APP_API_URL}/ums/admin/update/${user._id}`,{
            //             profile_image: base64String
            //         })
            //         if (response.success === true) {
            //             setCanvasMessage(successCanvasMessage);
            //             setShowCanvas(true);
            //             setTimeout(() => {
            //                 setShowCanvas(false);
            //                 window.location.href="/My-Profile";
            //             }, 1500)
            //         } else {
            //             throw new Error(response.message)
            //         }
            //     };

            // }
            const { data: response } = await axios.post(`${process.env.REACT_APP_API_URL}/admin/upload/profile_pic/${user._id}`, 
                formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            if (response.success === true) {
                setCanvasMessage(successCanvasMessage);
                setShowCanvas(true);
                setTimeout(() => {
                    setShowCanvas(false);
                    window.location.href = "/My-Profile";
                }, 1500)
            } else {
                throw new Error(response.message)
            }

        } catch (e) {
            console.error(e);
            setShowCanvas(true);
            setTimeout(() => {
                setShowCanvas(false);
                setUpdatedUser({
                    ...updatedUser, profile_image: undefined
                });
                setProfilePicPreview(undefined);
            }, 1500);

        }
    };

    const handleSave = async () => {
        try {
            const { first_name, last_name, contact_number, alternate_email } = updatedUser;
            const { data: response } = await axios.post(`${process.env.REACT_APP_API_URL}/ums/admin/update/${user._id}`, {
                first_name,
                last_name,
                contact_number,
                alternate_email
            })
            if (response.success === true) {
                setCanvasMessage(successCanvasMessage);
                setShowCanvas(true);
                setTimeout(() => {
                    setShowCanvas(false);
                    window.location.href = "/My-Profile";
                }, 1500)
            } else {
                throw new Error(response.message)
            }

        } catch (e) {
            console.error(e);
            setCanvasMessage(FailCanvasMessage);
            setShowCanvas(true);
            setTimeout(() => {
                setShowCanvas(false);
                setUpdatedUser({
                    first_name: user.first_name,
                    last_name: user.last_name,
                    contact_number: user.contact_number,
                    profile_image: user.profile_image,
                    email: user.email
                });
            }, 1500)

        } finally {
            toggleEditMode();
        }
    };

    const toggleEditMode = () => {
        setEditMode(prev => (!prev));
    }

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            <i className="">
                Change picture
            </i>
        </Tooltip>
    );

    const triggerImageInput = () => {
        const imgInputElement = document.getElementById("my-profile-upload-pic-button");
        imgInputElement.click();
    }

    const removeProfilePic = async () => {
        try {
            const { data: response } = await axios.post(`${process.env.REACT_APP_API_URL}/ums/admin/update/${user._id}`, {
                profile_image: null
            })
            if (response.success === true) {
                setCanvasMessage(successCanvasMessage);
                setShowCanvas(true);
                setTimeout(() => {
                    setShowCanvas(false);
                    window.location.href = "/My-Profile";
                }, 1500)
            } else {
                throw new Error(response.message)
            }
            setProfilePicPreview(() => {
                setUpdatedUser({ ...updatedUser, profile_image: undefined })
            })
            return undefined;
        }
        catch (e) {
            console.error(e);
            setCanvasMessage(FailCanvasMessage);
            setShowCanvas(true);
            setTimeout(() => {
                setShowCanvas(false);
                setUpdatedUser({
                    first_name: user.first_name,
                    last_name: user.last_name,
                    contact_number: user.contact_number,
                    profile_image: user.profile_image,
                    email: user.email
                });
            }, 1500)
        }
        finally { }
    }

    return (
        <div className="">
            <Offcanvas show={showCanvas} placement='end' style={{ height: 'fit-content' }}>
                <Offcanvas.Body>
                    {canvasMessage}
                </Offcanvas.Body>
            </Offcanvas>
            <h2 className="nexus-header-primary mb-2 mx-3">My Profile</h2>
            <div class="container rounded">
                <div class="row gap-5 gap-md-0">
                    <div class="col-md-3 px-auto ">
                        <div class="d-flex flex-column align-items-center text-center  py-5 nexus-background-primary-gradient-left text-white  mt-3 rounded" style={{ wordBreak: "break-word" }}>
                            <div className="profile-pic position-relative">
                                <div className="p-2 border border-2 rounded-circle">
                                    {profilePicPreview ?
                                        // <div
                                        //     className="rounded-circle shadow-lg d-flex align-items-center 
                                        //     justify-content-center p-5 nexus-background-primary-dark text-white fs-2"
                                        //     style={{ backgroundImage: `url(${profilePicPreview})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', overflow: 'hidden' }}
                                        // >
                                        // </div>
                                        <img src={profilePicPreview}/>
                                        :
                                        (
                                            <div
                                                className="rounded-circle shadow-lg d-flex align-items-center 
                                                justify-content-center p-3 nexus-background-primary-dark text-white fs-2"
                                            >
                                                {getInitials(updatedUser.first_name, updatedUser.last_name)}
                                            </div>
                                        )
                                    }
                                </div>
                                {/* <OverlayTrigger placement="bottom" overlay={renderTooltip}>
                                    <img src={edit_icon} height="20" className="edit-pic" onClick={triggerImageInput} />
                                </OverlayTrigger> */}

                            </div>

                            <span class="fw-bold mt-2">{updatedUser.first_name} {updatedUser.last_name}</span>
                            <span class="mt-1"><i>{updatedUser.email}</i></span>
                            <span> </span>
                            {/* <div className="pt-5 d-flex align-items-center justify-content-center gap-3">
                                <div className="btn btn-dark d-inline-flex">My Roles</div>
                                <div className="btn btn-dark d-inline-flex">My Groups</div>
                            </div> */}
                        </div>

                        {!editMode ?
                            <div className="nexus-button-primary-dark mt-2  d-flex align-items-center justify-content-center gap-2" onClick={toggleEditMode}>
                                <img src={edit_icon} height="15" />
                                Edit Profile
                            </div> :
                            <div className="nexus-button-primary-dark mt-2  d-flex align-items-center justify-content-center gap-2" value="save" onClick={handleSave}>
                                <img src={edit_icon} height="15" />
                                Save Profile
                            </div>
                        }
                        {/* <button className={` w-100 nexus-button bg-danger border-none text-white mt-2  d-flex align-items-center justify-content-center 
                            ${updatedUser.profile_image == undefined && 'bg-opacity-50'} `}
                            onClick={removeProfilePic}
                            value="save">
                            <img src={cancel_icon} height="25" />
                            Remove Picture
                        </button> */}
                        <input type="file" className="d-none" id="my-profile-upload-pic-button" accept=".jpg,.jpeg,.png" onChange={handleProfilePicChange} />
                    </div>
                    <div class="col-md-9 h-100 align-self-center">
                        <div class="p-3 nexus-border-secondary-dotted nexus-background-secondary position-relative rounded ">
                            {editMode &&
                                <div className="d-flex justify-content-end pe-4">
                                    <div className="btn btn-outline-danger" onClick={() => {setInitialValues();toggleEditMode()}}>
                                        Cancel
                                    </div>
                                </div>
                            }
                            <div class=" d-inline-flex nexus-background-secondary justify-content-between align-items-center p-2 rounded-5 nexus-border-secondary-dotted form-title">
                                <span class="">Profile Settings</span>
                            </div>
                            <div class="row mt-2 p-4">
                                <div class="col-md-6">
                                    <label class="labels form-label">First Name</label>
                                    <input type="text" class="form-control form-input" placeholder="First Name" name="first_name" onChange={handleInputChange} value={updatedUser.first_name} disabled={!editMode} />
                                </div>
                                <div class="col-md-6">
                                    <label class="labels form-label">Last Name</label>
                                    <input type="text" class="form-control form-input" value={updatedUser.last_name} name="last_name" onChange={handleInputChange} placeholder="Last Name" disabled={!editMode} />
                                </div>
                            </div>
                         
                            <div class="row px-4 mb-5">
                                <div class="col-md-6">
                                    <label class="labels form-label">Email ID</label>
                                    <input type="email" class="form-control form-input" name="email" placeholder="Enter Email Id" value={user.email} disabled />
                                </div>
                                <div class="col-md-6">
                                    <label class="labels form-label">Alternate Email</label>
                                    <input type="email" class="form-control form-input" placeholder="Enter Alternate Email" name="alternate_email" 
                                        value={editMode? updatedUser.alternate_email? updatedUser.alternate_email : '': updatedUser.alternate_email? updatedUser.alternate_email : 'N/A'}  
                                        onChange={handleInputChange}
                                        disabled={!editMode}
                                    />
                                </div>
                            </div>
                            <div class="row px-4 mb-5">
                                <div class="col-md-6">
                                    <label class="labels form-label">Roles</label>
                                    <div className="form-control-disabled">
                                    {(() => {
                                            if (user.selectedRoles?.length > 0) {
                                                var roles = user.selectedRoles.map(role => role.role_name);
                                                roles = roles.join(", ")
                                                return roles
                                            }
                                            else {
                                                return 'N/A'
                                            }
                                        })()}
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <label class="labels form-label">Groups</label>
                                    <div className="form-control-disabled">
                                    {(() => {
                                                if (user.groups_assigned?.length > 0) {
                                                    var groups = user.groups_assigned.map(group => group.name);
                                                    groups = groups.join(", ")
                                                    return groups
                                                }
                                                else {
                                                    return 'N/A'
                                                }
                                            })()}
                                    </div>
                                </div>
                            </div>
                            <div class="row px-4 mb-5">
                                <div class="col-md-6">
                                    <label class="labels form-label">Contact Number</label>
                                    <input type="text" class="form-control form-input" placeholder="Enter Contact Number" name="contact_number" 
                                        value={editMode? updatedUser.contact_number? updatedUser.contact_number : '': updatedUser.contact_number? updatedUser.contact_number : 'N/A'}  
                                        onChange={handleInputChange}
                                        disabled={!editMode}
                                    />
                                </div>
                               
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="d-flex flex-wrap mt-3 ">
                    <div className="flex-grow-1 px-3">
                        <h2 className="nexus-header-primary mb-2">My Roles</h2>
                        <table className="nexus-table-primary-stripped w-100" style={{maxHeight: '50vh', overflowY: 'auto'}}>
                            <thead>
                                <td>Role Name</td>
                                <td>Role Description</td>
                            </thead>
                            <tbody>
                                {user.selectedRoles.length > 0 && user.selectedRoles.map(role => (
                                    <tr>
                                        <td>{role.role_name}</td>
                                        <td>{role.description || 'N/A'}</td>
                                    </tr>
                                ))}
                                 {!user.selectedRoles?.length && 
                                    <tr>
                                        <td colSpan={2} className="text-danger fw-bold">
                                            Sorry No data found
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="flex-grow-1 px-3">
                        <h2 className="nexus-header-primary mb-2">My Groups</h2>
                        <table className="nexus-table-primary-stripped w-100" style={{maxHeight: '50vh', overflowY: 'auto'}}>
                            <thead>
                                <td>Group Name</td>
                                <td>Group Description</td>
                            </thead>
                            <tbody>
                                {user.groups_assigned.length > 0 && user.groups_assigned.map(group => (
                                    <tr>
                                        <td>{group.name}</td>
                                        <td>{group.description}</td>
                                    </tr>
                                ))}
                                {!user.groups_assigned?.length && 
                                   <tr>
                                    <td colSpan={2} className="text-danger fw-bold">
                                        Sorry No data found
                                    </td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
                <div className="flex-grow-1 px-3">
                    <h2 className="nexus-header-primary mb-2">My Groups</h2>
                    <table className="nexus-table-primary-stripped w-100" style={{ maxHeight: '50vh', overflowY: 'auto' }}>
                        <thead>
                            <td>Group Name</td>
                            <td>Group Description</td>
                        </thead>
                        <tbody>
                            {user.groups_assigned.length > 0 && user.groups_assigned.map(group => (
                                <tr>
                                    <td>{group.name}</td>
                                    <td>{group.description}</td>
                                </tr>
                            ))}
                            {!user.groups_assigned?.length &&
                                <tr>
                                    <td colSpan={2} className="text-danger fw-bold">
                                        Sorry No data found
                                    </td>
                                </tr>
                            }
                        </tbody>
                    </table>

                    </div>
            </div> */}
        </div>
    )


};

export default MyProfile;
