import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import "./AddExamPatternForm.css";
import Context from '../../user_details';
import axios from 'axios';
import SpinnerLoader from '../../helpers/spinner-loader';
import { playSound } from '../../helpers/otherSounds';
import MarksDefinitionModal from '../AddExamForm/marksDefinitionModal';
import {Modal} from 'react-bootstrap';


export default function AddExamPatternForm({ onAddExamPattern, toggleUpdateLoader }) {
  const navigate = useNavigate();
  const {user} = useContext(Context);
  const [examPatternName, setExamPatternName] = useState('');
  const [loginRequired, setLoginRequired] = useState(false);
  const [isSingleSubjectExam, setIsSingleSubjectExam] = useState(true);
  const [videoProctoring, setVideoProctoring] = useState(false);
  const [browserProctoring, setBrowserProctoring] = useState(false);
  const [numberOfSections, setNumberOfSections] = useState(1);
  const [sectionwiseTimings, setSectionwiseTimings] = useState(false);
  const [sectionQuestionTypes, setSectionQuestionTypes] = useState([]);
  const [fileUploadQuestions, setFileUploadQuestions] = useState({
    numberOfQuestions: 0,
    sectionWiseTimeSlots: false
  });
  const [current__name_edit,setCurrentNameEdit] = useState();
  const [autoAssignQuestions, setAutoAssignQuestions] = useState(false);
  const [numberOfEasyQuestions, setNumberOfEasyQuestions] = useState(0);
  const [numberOfMediumQuestions, setNumberOfMediumQuestions] = useState(0);
  const [numberOfDifficultQuestions, setNumberOfDifficultQuestions] = useState(0);
  const [selectedSection, setSelectedSection]  = useState(1);
  const [sectionValues, setSectionValues] = useState([
                                                      {
                                                        section_name: `Section 1`,
                                                        question_types:[
                                                          {
                                                          question_type: 9999,
                                                          no_of_easy_questions: 0,
                                                          no_of_hard_questions: 0,
                                                          no_of_medium_questions: 0,
                                                          no_of_comprehensives: 0,
                                                          min_questions_per_comprehensive: 0,
                                                          no_of_questions: 0
                                                          }
                                                        ]
                                                      }     
                                                    ]);
  const [sectionValueLoader, setSectionValueLoader] = useState(true);
  const [selectedFirm, setSelectedFirm] = useState(onAddExamPattern? onAddExamPattern.firm_id: user.user_type == 'superAdmin'? '' : user.firm_id);
  const [isFirmsLoaded, setFirmsListLoaded] = useState(onAddExamPattern? true: !(user.user_type == 'superAdmin'));
  const [firmsList, setFirmsList] = useState([]);
  const marksPerQuestionDifficulty = {easy: 1, medium: 2, hard: 4};
  const [showMarksModal, setShowMarksModal] = useState(false);
  

  console.log("this is add exam pattern value", onAddExamPattern)

  useEffect(()=>{
    

    if (onAddExamPattern) {
      // console.log(onAddExamPattern,"exam pattern update")
      setSectionValueLoader(true);
      setSectionValues([...onAddExamPattern.sections]);
      setExamPatternName(onAddExamPattern.exam_pattern_name);
      setLoginRequired(onAddExamPattern.login_required);
      setIsSingleSubjectExam(onAddExamPattern.single_subject_exam);
      setVideoProctoring(onAddExamPattern.video_proctoring);
      setBrowserProctoring(onAddExamPattern.browser_proctoring);
      setNumberOfSections(onAddExamPattern.no_of_sections);
      setSectionwiseTimings(onAddExamPattern.section_wise_timings);
      setSectionValueLoader(false);
    } else {
      const getFirmsList = async () => {
        try {
          const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/ums/firms/list`);
          setFirmsList(data.results);
          setFirmsListLoaded(true);
        }
        catch (e) {
          console.error(e);
        }
  
      }
      if (user.user_type=='superAdmin' && !isFirmsLoaded) {
        getFirmsList();
      }
    }
  },[]);

  useEffect(()=>{
    setSectionValueLoader(false);
    console.log("changes in section values", sectionValues)
  },[sectionValues])

  useEffect(() => {
    if (numberOfSections > sectionValues.length) {
      let temporarySections =  Array.from({ length: numberOfSections - sectionValues.length },
                              (_,index) => {
                               return {
                                  section_name: `Section ${ sectionValues.length + 1 + index}`,
                                  question_types:[
                                    {
                                    question_type: 9999,
                                    no_of_easy_questions: 0,
                                    no_of_hard_questions: 0,
                                    no_of_medium_questions: 0,
                                    no_of_comprehensives: 0,
                                    min_questions_per_comprehensive: 0,
                                    no_of_questions: 0
                                    }
                                  ]
                                }
                              });
                              
      setSectionValues(prev => {return [...prev, ...temporarySections]});
    }
    else {
      let temporarySections = sectionValues;
      temporarySections.splice(temporarySections.length -  sectionValues.length + numberOfSections ,sectionValues.length - numberOfSections);
    }
    
  }, [numberOfSections]);

  const calculateTotalMarks = async() => {
    var total = 0;
    await Promise.all(
      sectionValues.map(async(sectionValue) => {
        await Promise.all(
          sectionValue.question_types.map((questionType) => {
            var {no_of_easy_questions, no_of_hard_questions, no_of_medium_questions} = questionType;
            no_of_easy_questions = parseInt(no_of_easy_questions)|| 0;
            no_of_medium_questions = parseInt(no_of_medium_questions) || 0;
            no_of_hard_questions = parseInt(no_of_hard_questions) ||  0;
            total += no_of_easy_questions*marksPerQuestionDifficulty.easy + no_of_medium_questions*marksPerQuestionDifficulty.medium + no_of_hard_questions*marksPerQuestionDifficulty.hard;
          })
        )
      })
    );
    return total;
  }

  const handleSubmit = async (event) => {
    const hasPermission = await user.permissionTypes.includes("createExamPatterns");
    if (!hasPermission) return;
    event.preventDefault();
    try {
      const totalMarks = await calculateTotalMarks();
      // Send a POST request to the server with the form data
      const response = await axios.post(process.env.REACT_APP_API_URL+'/examPattern/create', {
        exam_pattern_name: examPatternName,
        login_required: loginRequired,
        single_subject_exam: isSingleSubjectExam,
        multi_subject_exam: !isSingleSubjectExam,
        video_proctoring: videoProctoring,
        browser_proctoring: browserProctoring,
        no_of_sections: numberOfSections,
        sections: sectionValues,
        section_wise_timings: sectionwiseTimings,
        firm_id: selectedFirm,
        total_marks: totalMarks
      });
      if(response.data.success){
        document.getElementById("examPatternSuccessCreate").click();
        playSound('SUCCESS');
        setTimeout(()=>{
          document.getElementById("examPatternSuccessClose").click();
          navigate("/examPatterns");
        },1000)
      }
      // Trigger the callback function passed from the parent component
      //onAddExamPattern(); //this is not a function to call
      // Reset form fields
      setExamPatternName('');
      setLoginRequired(false);
      setIsSingleSubjectExam(true);
      setVideoProctoring(false);
      setBrowserProctoring(false);
      setNumberOfSections(1);
      setSectionQuestionTypes([]);
      setFileUploadQuestions({
        numberOfQuestions: 0,
        sectionWiseTimeSlots: false
      });
      setAutoAssignQuestions(false);
      setNumberOfEasyQuestions(0);
      setNumberOfMediumQuestions(0);
      setNumberOfDifficultQuestions(0);
    } catch (error) {
      playSound('ERROR');
      alert('Error While Adding Exam Pattern.');
      console.error('Error adding exam pattern:', error);
    }
  };

  const updatePatternApi = async() => {
    const hasPermission = await user.permissionTypes.includes("editExamPatterns");
    if ( hasPermission) {
      const totalMarks = await calculateTotalMarks();
      const response = await axios.patch(process.env.REACT_APP_API_URL+`/examPattern/update/${onAddExamPattern._id}`,
        {
          exam_pattern_name: examPatternName,
          login_required: loginRequired,
          single_subject_exam: isSingleSubjectExam,
          multi_subject_exam: !isSingleSubjectExam,
          video_proctoring: videoProctoring,
          browser_proctoring: browserProctoring,
          no_of_sections: numberOfSections,
          sections: sectionValues,
          section_wise_timings: sectionwiseTimings,
          updated: {by:user._id},
          firm_id: selectedFirm,
          total_marks: totalMarks
        }
      )
      toggleUpdateLoader();
      if(response.status == 200) {
        
        document.getElementById("examPatternSuccessUpdate").click();
        
        playSound('SUCCESS');
        setTimeout(()=>{
          document.getElementById("examPatternUpdateClose").click();
        },3000)
      } else {
        playSound('ERROR');
        alert('Error while updating the Exam Pattern.');
      }           
    }
  }

  const changeSectionValue = (event) => {
    try {
      console.log("inside change")
      let temporarySections = sectionValues;
      if (event.target.name === 'section_name'){
        temporarySections[selectedSection-1][event.target.name] = event.target.value;
      }
      else if(event.target.name === 'question_type') {
        setSectionValueLoader(true);
        temporarySections[selectedSection-1].question_types[0][event.target.name] = parseInt(event.target.value);
        setTimeout(()=>{
          setSectionValueLoader(false)
        },100);
      }
      else temporarySections[selectedSection-1].question_types[0][event.target.name] = event.target.value;
      console.log("temp",temporarySections)
      setSectionValues([...temporarySections]);
    }
    catch(msg) {
      setSectionValueLoader(false)
      console.log(msg)
    }
    finally{}
  }

  return (
    <div className='pattern_form exam-pattern-form-main-container mt-3'>
      { !onAddExamPattern && <div className='form-container-main-header'> New Exam Pattern</div>}
      {/*--------------------------- Successful create modal message -----------------------------*/}
      <button type="button" className="btn btn-primary d-none" id="examPatternSuccessCreate" data-bs-toggle="modal" data-bs-target="#patternCreateModal"></button>
      <div className="modal fade" id="patternCreateModal" tabIndex="-1" role="dialog" aria-labelledby="patternCreateModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content" style={{left:'100%'}}>
            <div className="modal-body d-flex align-items-center bg-success text-white justify-content-between">
              <div>Exam Pattern Successfully Created!</div>
              <div className='btn btn-success p-0 px-1' data-bs-dismiss="modal" id="examPatternSuccessClose" aria-label="Close"> &times;</div>
            </div>
          </div>
        </div>
      </div>
      <button type="button" className="btn btn-primary d-none" id="examPatternSuccessUpdate" data-bs-toggle="modal" data-bs-target="#patternUpdateModal"></button>
      <div className="modal fade" id="patternUpdateModal" tabIndex="-1" role="dialog" aria-labelledby="patternCreateModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content" style={{left:'100%'}}>
            <div className="modal-body d-flex align-items-center bg-success text-white justify-content-between">
              <div>Exam Pattern Successfully Updated!</div>
              <div className='btn btn-success p-0 px-1' data-bs-dismiss="modal" id="examPatternUpdateClose" aria-label="Close"> &times;</div>
            </div>
          </div>
        </div>
      </div>
      {/* ----------------------------------------------------------------------------------------*/}
      {isFirmsLoaded ? 
      <form className=''  onSubmit={handleSubmit} >
      <div className='row'>
        <div className='col-md-12'>
          <div className='form-elements-container mb-2'>
            <div className='label-area width-15'>
              <label className='text-right'>Exam Pattern Name</label>
            </div>
            <div className='form-info-entry-area width-85'>
              <input type="text" className="form-control" value={examPatternName}
                onChange={(e) => setExamPatternName(e.target.value)} required />
            </div>
          </div>
          <br />
        </div>
        {
          user.user_type=='superAdmin' && !onAddExamPattern && 
          <div className='col-md-12'>
          <div className='form-elements-container mb-2'>
            <div className='label-area width-15'>
              <label className='text-right'>Select Firm</label>
            </div>
            <div className='form-info-entry-area width-85'>
              <select className='pattern-dropdown-element w-25 p-2 fs-6 ' 
                required 
                onChange={e=> {setSelectedFirm(e.target.value)}}
                value={selectedFirm}>
                <option value="">---  Select A Firm  ---</option>
                {firmsList.map(firm => (
                  <option value={firm._id}>{firm.firm_name}</option>
                ))}
              </select>
            </div>
          </div>
          <br />
        </div>
        }
        <div className='col-md-4'>
          {/* --------------------------------------------- */}
          
          <div className='form-elements-container mb-3'>
            <div className='label-area w-auto d-block mb-2'>
              <label className='text-right'>Exam Type</label>
            </div>
            <div className='form-info-entry-area'>
                <div className='multiselect-container mb-2'>
                    <input type="radio" className="form-check-input" id="singleSubjectExam"
                      value="single" checked={isSingleSubjectExam} 
                      onChange={() => setIsSingleSubjectExam(true)} />
                    <label className=''>Single Subject Exam</label>
                </div>
                <div className='multiselect-container mb-2' title='Feature coming soon.'>
                  <input type="radio" className="form-check-input" id="multiSubjectExam"
                    value="multi" checked={!isSingleSubjectExam}
                    onChange={() => setIsSingleSubjectExam(false)} disabled/>
                  <label className=''>Multi Subject Exam</label>
                </div>
              </div>
            </div>

          <div className='form-elements-container mb-3'>
            <div className='label-area w-auto d-block mb-2'>
              <label className='text-right d-block'>Choose Your Preferences</label>
            </div>
            <div className='form-info-entry-area'>
              <div className='multiselect-container d-block mb-2'>
                <input type="checkbox" className="form-check-input" id="loginRequired"
                  checked={loginRequired} onChange={(e) => setLoginRequired(e.target.checked)} />
                <label className="form-check-label" htmlFor="loginRequired">Login Required</label>
              </div>

              <div className="multiselect-container d-block mb-2">
                <input type="checkbox" className="form-check-input" id="videoProctoring"
                  checked={videoProctoring} onChange={(e) => setVideoProctoring(e.target.checked)} />
                <label className="form-check-label" htmlFor="videoProctoring">Video Proctoring (AI Powered)</label>
              </div>

              <div className="multiselect-container d-block mb-2">
                <input type="checkbox" className="form-check-input" id="browserProctoring"
                  checked={browserProctoring} onChange={(e) => setBrowserProctoring(e.target.checked)} />
                <label className="form-check-label" htmlFor="browserProctoring">Browser Proctoring</label>
              </div>

              <div className="multiselect-container d-block mb-2" title="Feature coming soon.">
                <input type="checkbox" className="form-check-input" id="remoteInvigilation"  disabled />
                <label className="form-check-label" htmlFor="remoteInvigilation">Remote Invigilation</label>
              </div>
            </div>
          </div>
          {/* --------------------------------------------- */}
        </div>
        <div className='col-md-8'>
          <h2 className='inner-section-container-header'>Create Sections</h2>

          <div className='sections-create-container'>
            <div className='form-elements-container'>
              <div className='label-area w-auto me-3'>
                <label className="form-label" htmlFor="section_number_input">
                  Number Of Sections:                         
                </label>
              </div>
              <div className='form-info-entry-area'>
              <input type="number" className="form-control text-center" value={numberOfSections}
                  onChange={(e) => {if(e.target.value >= 1 ) {setNumberOfSections(parseInt(e.target.value))}}}
                  min={1} id="section_number_input" required />
              </div>
            </div>

          {/* --------- Sections data starts here -------- */}

          <div className=' d-flex flex-grow-1 sections-creation-block' style={{overflowY:'auto'}}>
            <div className='d-flex flex-column create-exam-pattern-section-container align-self-stretch w-50'>
              {[...Array(numberOfSections)].map((element,index) => {
                // -----------------Sections Container------------------------
                if (current__name_edit == index) {
                  return (
                    <div key={index} onClick={() => {setSelectedSection(index+1)}} className={selectedSection === (index + 1) ? " gap-3 d-flex flex-column align-items-stretch text-center p-3 bg-selected-item": "text-center border border-1  border-secondary p-4" } style={{cursor:"pointer"}}>
                        {/* <span className='fs-6'>Name:</span> */}
                        <input type="text" name="section_name" placeholder="Enter Section name"  className='w-100' autoFocus onBlur={(e)=>{setCurrentNameEdit();changeSectionValue(e)}}/>
                    </div>
                    )
                }
                else return (
                  <div key={index} onClick={() => {setSelectedSection(index+1)}} className={selectedSection === (index + 1) ? " d-flex align-items-center justify-content-center text-center p-3 bg-selected-item": "d-flex align-items-center justify-content-center text-center brdr-top-btm-light p-3" } style={{cursor:"pointer"}}>
                      {sectionValues[index]?.section_name} 
                      <span className="ms-auto" onClick={()=>{setCurrentNameEdit(index)}}>&#9998;</span>
                  </div>
                  )
              })}
            </div>
        {/* -------------------------section details container ---------------------------- */}
        <div className='align-self-stretch select-question-type-container w-100 d-flex flex-column align-items-start'>
        {/* {sectionValueLoader? "Loading..." : */}
           {sectionValueLoader? 
            <div className="h-100 w-100 d-flex justify-content-center align-items-center">
              <SpinnerLoader />
            </div> : 
            <div className='d-flex flex-column'>
                <div className='d-flex gap-2'>
                <label> Select Question Type</label>
                <select name="question_type" onChange={(e)=>{changeSectionValue(e)}}>
                  <option value={9999} selected={sectionValues[selectedSection - 1].question_types[0].question_type == 9999}>---Select question type---</option>
                  <option value={1} selected={sectionValues[selectedSection - 1].question_types[0].question_type == 1}>Multiple Choice</option>
                  <option value={2} selected={sectionValues[selectedSection - 1].question_types[0].question_type == 2}>True/False</option>
                  <option value={3} selected={sectionValues[selectedSection - 1].question_types[0].question_type == 3}>Check Boxes</option>
                  <option value={4} selected={sectionValues[selectedSection - 1].question_types[0].question_type == 4} >Comprehensive</option>
                  <option value={5} selected={sectionValues[selectedSection - 1].question_types[0].question_type == 5} disabled>File upload</option>
                </select>
              </div>
              
              {[1,2,3].includes(sectionValues[selectedSection - 1].question_types[0].question_type) ?

                <div className='d-flex flex-column'>
                  <br />
                    <div className='d-flex gap-3 align-items-center mb-1'>
                        <label className='w-50 font-normal'>No Of Easy Questions</label>
                        <input type="number"
                        className="form-control w-25"
                        name="no_of_easy_questions"
                        value={sectionValues[selectedSection - 1].question_types[0].no_of_easy_questions}
                        onChange={(e) => {if(e.target.value >= 1 ) {changeSectionValue(e)}}}
                        required/>
                    </div>
                    <div className='d-flex gap-3 align-items-center mb-1'>
                        <label  className='w-50 font-normal'>No Of Medium Questions</label>
                        <input className='form-control w-25 ' 
                        type="number"
                        name="no_of_medium_questions"
                        value={sectionValues[selectedSection - 1].question_types[0].no_of_medium_questions}
                        onChange={(e) => {if(e.target.value >= 1 ) {changeSectionValue(e)}}}   
                        min={0} 
                        required                                                                                                    
                        />
                    </div>
                    <div className='d-flex gap-3 align-items-center mb-1'>
                        <label  className='w-50 font-normal'>No Of Hard Questions</label>
                        <input className='form-control w-25 ' 
                        type="number"
                        name="no_of_hard_questions"
                        value={sectionValues[selectedSection - 1].question_types[0].no_of_hard_questions}
                        onChange={(e) => {if(e.target.value >= 1 ) {changeSectionValue(e)}}}    
                        required  />
                    </div>
                    
                    
                        
                    
                </div>:
                sectionValues[selectedSection - 1].question_types[0].question_type == 4?
                    <div className='d-flex flex-column gap-3'>
                      <br />
                      <div className='d-flex gap-3 align-items-center mb-1'>
                        <label className='w-50 font-normal'>No Of Comprehensives</label>
                        <input className='form-control w-25'
                          type="number"
                          name="no_of_comprehensives"
                          value={sectionValues[selectedSection - 1].question_types[0].no_of_comprehensives}
                          onChange={(e) => { if (e.target.value >= 1) { changeSectionValue(e) } }}
                          required />
                      </div>
                      
                    </div> :
                    sectionValues[selectedSection - 1].question_types[0].question_type == 5?
                    <div className='d-flex gap-3 align-items-center mb-1 mt-3'>
                        <label className='w-50 font-normal'>No Of Questions</label>
                        <input className='form-control w-25'
                        type="number"
                        name="no_of_questions"
                        value={sectionValues[selectedSection - 1].question_types[0].no_of_questions}
                        onChange={(e) => {if(e.target.value >= 1 ) {changeSectionValue(e)}}}    
                        required  
                        />
                    </div>:
                    ""
              }
            </div>
            }
          </div>
       
        </div>
        {
        numberOfSections > 1 && 
        <div className='d-flex gap-2 align-items-center mt-3'>
          <input
              type="checkbox"
              className="form-check-input m-0"
              id="section_wise_timings"
              checked={sectionwiseTimings}
              onChange={()=>{setSectionwiseTimings(prev => {return (!prev)})}} 
              disabled
          />
          <label className="form-check-label d-inline-flex" htmlFor="section_wise_timings">Section Wise Timings</label>
        </div>
        }
          
          {/* --------- Sections data ends here -------- */}

          </div>

        </div>
        <div className='col-12'>
          <hr className='form-btn-seperator' />
        </div>
      </div>
      

    
     { !onAddExamPattern? 
      <div className='d-flex justify-content-center gap-3'>
         <button className='align-self-center app-main-form-btn' type="submit">Create Pattern</button>
         <button className='nexus-button-dark-outline' onClick={() =>{navigate("/examPatterns")}} >Cancel</button>
      </div>
      :<div className='d-flex align-items-center justify-content-end gap-2 mt-auto pt-1'>
        {user.permissionTypes.includes("editExamPatterns") && <div className='btn btn-outline-success' data-bs-dismiss="modal" onClick={updatePatternApi}>Save Changes</div>}
        <div className='btn btn-outline-danger' data-bs-dismiss="modal">Cancel</div>
      </div>
    }
    </form> : 
    <div className='h-100 w-100 d-flex align-items-center justify-content-center'>
      <SpinnerLoader />
    </div>  
    }
    </div>
  );
}
