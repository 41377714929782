import axios from 'axios';
import { playSound } from '../../helpers/otherSounds';
import ExamFormValidator from './ExamFormValidator';

// Define the handleSubmit function
export const handleSubmitHelper = async (event, {
  isSubmitting, setIsSubmitting, selectedExamPattern, selectedUserGroup, 
  selectedCourse, user, sectionValues, examName, negativeMarks, questionsPerNegative,
  passMarks, selectedSubject, navigate, setActionLoader, props, examUtcOffset, selectedExamTimeZone,selectedFirm,
  setValidationCanvasMessage,setShowValidationCanvas,totalMarks
}) => {
  event.preventDefault();

  if (isSubmitting) return;
  const validation = await ExamFormValidator({setShowValidationCanvas, setValidationCanvasMessage, sectionValues});
  if(!validation) return;
  setIsSubmitting(true);

  let key, value;
  if (selectedExamPattern.login_required) {
    key = 'user_group';
    value = selectedUserGroup;
  } else {
    key = 'examinee_email';
    value = document.getElementById("examineeEmails").value.split(",").map(email => email.trim());
  }
  var totalMarks = 0;
  const complexityLabels = {
    easy: 1,
    medium: 2,
    hard: 3
  };
  for(const section of sectionValues) {
    const assignedQuestions = section.assignedQuestions[0];
    const {marks_allotment} = section;
    for(const qType in marks_allotment) {
      switch(Number(qType)) {
        case 1:
        case 2:
        case 3: {
          for(let complexity in marks_allotment[qType]) {
            if(assignedQuestions[qType][complexityLabels[complexity]].pick > 0) {
              totalMarks += Number(marks_allotment[qType][complexity].marks);
            }
          }
          break;
        };
        case 4: {
          if(assignedQuestions[qType].pick > 0){
            totalMarks += Number( marks_allotment[qType].marks);
          }
          break;
        }
      }
    }
  }
  let newExam = {
    examName,
    selectedExamPattern: selectedExamPattern._id,
    selectedCourse: selectedCourse._id,
    client_id: selectedFirm,
    sections: sectionValues,
    created: { by: user._id },
    status: 1,
    total_marks: totalMarks,
    [key]: value,
    negativeMarks: negativeMarks,
    questionsPerNegative: questionsPerNegative,
    pass_marks: passMarks,
    timeZoneOffset: {zone: selectedExamTimeZone, offset: examUtcOffset}
  };

  if (!selectedExamPattern.section_wise_timings) {
    let startDate = document.getElementById('startdatetime').value;
    let endDate = document.getElementById('enddatetime').value;
    newExam = { ...newExam, startDate, endDate };
  }

  if (selectedExamPattern.single_subject_exam) {
    newExam = { ...newExam, selectedSubject };
  }

  try {
    let result;
    if (!Object.keys(props).includes("selectedExam")) {
      setActionLoader(true);
      result = await axios.post(`${process.env.REACT_APP_API_URL}/exam/create`, newExam);
    } else {
      let { updated } = props.selectedExam;
      updated.push({ by: user._id });
      newExam = { ...newExam, updated };
      setActionLoader(true);
      result = await axios.patch(`${process.env.REACT_APP_API_URL}/exam/update/${props.selectedExam._id}`, newExam);
    }

    if (result.data.success) {
      document.getElementById("examCreateSuccessMsgToggler")?.click();
      setIsSubmitting(false);
      setTimeout(() => {
        document.getElementById("examCreateSuccessClose")?.click();
        navigate('/exams');
      }, 3000);
      playSound('SUCCESS');
    } else {
      setIsSubmitting(false);
      playSound('ERROR');
      alert("Unable to create / update exam. Please try later");
    }
    setActionLoader(false);
  } catch (e) {
    console.error(e);
    setTimeout(() => {
      setActionLoader(false);
    }, 3000);
    setIsSubmitting(false);
    playSound('ERROR');
    alert("Creation error: exam");
  }
};
