// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.courses-container {
  /* margin: 20px; */
  padding: 25px;
}

.course-list {
  list-style-type: none;
  padding: 0;
}

.course-item {
  margin-bottom: 10px;
}

.pagination {
  display: flex;
  list-style-type: none;
  padding: 0;
}

.page-item {
  margin-right: 5px;
}

.active {
  font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/components/Courses/courses.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE,qBAAqB;EACrB,UAAU;AACZ;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,qBAAqB;EACrB,UAAU;AACZ;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".courses-container {\n  /* margin: 20px; */\n  padding: 25px;\n}\n\n.course-list {\n  list-style-type: none;\n  padding: 0;\n}\n\n.course-item {\n  margin-bottom: 10px;\n}\n\n.pagination {\n  display: flex;\n  list-style-type: none;\n  padding: 0;\n}\n\n.page-item {\n  margin-right: 5px;\n}\n\n.active {\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
