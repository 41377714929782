import React from 'react';

const StudentsList = ({ detailedExamData }) => {
  
  return (
    <div className='report-container  w-100' >
        <h3>{detailedExamData.examName}</h3>
        <table className='table exam-analysis-table'>
        <tbody>
            <tr><th className='' style={{width:'35%'}}>Student Name</th><td>{detailedExamData.StudentDetails.first_name} {detailedExamData.StudentDetails.last_name}</td></tr>
            <tr><th className='' style={{width:'35%'}}>Email</th><td>{detailedExamData.StudentDetails.email}</td></tr>
            <tr>
            <th>Scored / Total Marks</th>
            <td>
              {`${detailedExamData.scoredMarks} / ${detailedExamData.totalMarks}`}
            </td>
            </tr>

            <tr><th>Correct Answers</th><td>{detailedExamData.correctAnswersCount}</td></tr>
            <tr><th>Incorrect Answers</th><td>{detailedExamData.incorrectAnswersCount}</td></tr>
            <tr><th>Skipped Answers</th><td>{detailedExamData.skippedAnswersCount}</td></tr>
            {detailedExamData.negative_marks && (
            <tr><th>Negative Marks</th><td>{detailedExamData.negative_marks.negative_marks}</td></tr>
            )}
            <tr><th>Attended Date</th><td>{new Date(detailedExamData.attendedDate).toLocaleDateString()}</td></tr>
            {/* <tr><th>Time Spent</th><td>{detailedExamData.timeSpentTotal} seconds</td></tr> */}
            <tr>
            <th>Exam Initiated Date & Time</th>
            <td>
                {detailedExamData.exam_initiated_time? detailedExamData.exam_initiated_time.split('T')[0] +' '+ detailedExamData.exam_initiated_time.split('T')[1].split('.')[0] : 'NA'}
            {}
            </td>
            </tr>
            {/* <tr><th>Exam Initiated Time</th><td>{detailedExamData.exam_initiated_time.split('T')[1].split('.')[0] }</td></tr> */}
        </tbody>
      </table>
    </div>
  );
};

export default StudentsList;
