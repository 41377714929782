import React, { useEffect, useState } from "react";
import axios from "axios";

const SearchForm = ({ user, searchParams, setSearchParams, resetSearch, get_users_list }) => {
  
  const [firms, setFirms] = useState([]);
  const [roles, setRoles] = useState([]);
  const [groups, setGroups] = useState([]);
  const [isSuperAdmin, setIsSuperAdmin] = useState(user.user_type === 'superAdmin');

  // Fetch the firm list only if user is superAdmin
  useEffect(() => {
    if (isSuperAdmin) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/ums/firms/list`)
        .then((response) => {
          setFirms(response.data.results);
        })
        .catch((error) => {
          console.error("Error fetching firms:", error);
        });
    } else {
      fetchRolesAndBatches(user.firm_id);
    }
  }, [isSuperAdmin]);

  // Fetch roles and batches for a specific firm
  const fetchRolesAndBatches = async (firmId) => {
    // Fetch roles
    await axios
      .get(`${process.env.REACT_APP_API_URL}/ums/roles/list?type=user&firm_id=${firmId}`)
      .then(response => {
        setRoles(response.data);
      })
      .catch((error) => {
        console.error("Error fetching roles:", error);
      });

    // Fetch groups
    axios
      .get(process.env.REACT_APP_API_URL + "/ums/groups/list?type=" + user.user_type+ "&firm_id=" +firmId)
      .then((response) => {
        setGroups(response.data);
      })
      .catch((error) => {
        console.error("Error fetching batches:", error);
      });
  };

  // Handle changes in the firm dropdown and update roles and batches
  const handleFirmChange = (event) => {
    const selectedFirmId = event.target.value;
    setSearchParams({ ...searchParams, firm: selectedFirmId });
    fetchRolesAndBatches(selectedFirmId);
  };

  // Handle form input changes
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSearchParams({ ...searchParams, [name]: value });
  };

  // Handle form submission
  const getUserListBySearch = (event) => {
    event.preventDefault();
    get_users_list();
  };

  return (
    <div className="general-add-form-container mt-3">
      
      <form onSubmit={getUserListBySearch}>
        <div className="row">
            <div className="col-md-4">
            <input
                placeholder="Name"
                className="form-control"
                type="text"
                name="name"
                value={searchParams.name}
                onChange={handleInputChange}
            />
            </div>
            <div className="col-md-4">
            <input
                placeholder="Email"
                className="form-control"
                type="email"
                name="email"
                value={searchParams.email}
                onChange={handleInputChange}
            />
            </div>
            {isSuperAdmin && (
            <div className="col-md-4">
                <select
                className="form-control"
                name="firm"
                value={searchParams.firm}
                onChange={handleFirmChange}
                >
                <option value="">Select a Firm</option>
                {firms.map((firm) => (
                    <option key={firm._id} value={firm._id}>
                    {firm.firm_name}
                    </option>
                ))}
                </select>
            </div>
            )}
            <div className="col-md-4 mt-3">
                <select
                    className="form-control"
                    name="role"
                    value={searchParams.role}
                    onChange={handleInputChange}
                >
                    <option value="">Select a Role</option>
                    {roles.map((role, index) => (
                    <option key={role.role._id} value={role.role._id}>
                        {role.role.role_name}
                    </option>
                    ))}
                </select>
            </div>
            <div className="col-md-4 mt-3">
                <select
                    className="form-control"
                    name="group"
                    value={searchParams.group}
                    onChange={handleInputChange}
                >
                    <option value="">Select a Group</option>
                    {groups.map((group) => (
                    <option key={group.group._id} value={group.group._id}>
                        {group.group.name}
                    </option>
                    ))}
                </select>
            </div>
            <div className="col-md-12 mt-3 text-center">
                <button type="submit" className="app-btn-primary">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                    </svg>
                    &nbsp;Search
                </button>
                <button type="button" className="cancel-form-btn" onClick={resetSearch}>Reset</button>
            </div>
        </div>
      </form>
    </div>
  );
};

export default SearchForm;
