import {useEffect, useState} from 'react'
import Cookies from 'js-cookie'
import './login.css'
import login_screen_img from '../../assets/main-banner02.jpg';


function Login(props) {
    const [credentials, setCredentials] = useState({email:"", password:""})
    const [error_msg, setError] = useState("")

    useEffect(()=>{
        if(window.location.pathname !== '/') window.location.href="/"
    },[])
    
    const login_call=async ()=>{
        if(credentials.email.length === 0 || credentials.password.length === 0){
            setError("Please fill the credentials to login")
        }
        else{
            await fetch(process.env.REACT_APP_API_URL+"/ums/login",{
                method:"POST",
                headers:{
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(credentials)
            })
            .then(async(res)=>{
                const result = await res.json()
                console.log(result,"this is result")
                if(!result.success){                             
                    setError(result.message || 'Please Try Again!!')                
                }
                else{ 
                    if(['superAdmin', 'admin', 'user'].includes(result.result.user.user_type)) {
                        // Cookies.set("nexus_exam_login",true,{expires:9999});
                        Cookies.set("nexus_exam_id",result.result.user._id,{expires:9999});
                        // props.setUserDetails(result.result.user);                    
                        props.SwitchLoginStatus(true);
                    }
                    else {
                    setError("The Entered Email is not Registered")
                    }
                }

            })
            .catch((err)=>{
                console.log(err)
                setError("Somethings wrong!!! Please try again!")
                
            })
        }
    }

    const InputChange=(event)=>{
        event.preventDefault()
        if(event.keyCode === 13){
            login_call()
        }
        else{
            setCredentials({...credentials,[event.target.name]:event.target.value})
        }
    }
    return(
        <div className='login_page admin_page'>
            <div className='container-fluid ps-0'>
                <div className='row g-0 align-items-center'>
                    <div className='col-md-9'>
                        <p className="img_container">
                            <img src={login_screen_img} alt="NexusIqSolutions" />
                        </p>
                    </div>
                    <div className='col-md-3'>
                        <div className='login_container'>
                            <p className='logo_img_container'>
                                <img src="/logos/nexsaa-logo.jpg"  alt="NexusIqSolutions" className='img-fluid' style={{maxWidth: '175px'}} />
                            </p>

                            <br/>
                            <h2>Login</h2>
                            <div className="">
                                <label htmlFor="email" className="input_text">Email</label>
                                <input type="email" name="email" placeholder="Enter Email" className="input_element" id="email" onKeyUp={InputChange}/>
                            </div>
                            <br/>
                            <div className="">
                                <label htmlFor="password" className="input_text">Password &nbsp;</label>
                                <input type="password" name="password" placeholder="Enter Password" id="password" className="input_element" onKeyUp={InputChange}/>
                            </div>
                            <br/>
                            <p id="error_msg">{error_msg}</p>
                            <input type="button" className="login_button" value="Login" onClick={login_call}/>
                            
                            <p class="forgot_pwd mb-0">
                                <a href="/forgotPassword" className='mt-3'>Forgot Password?</a>
                            </p>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Login