import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from 'recharts';
import Context from '../../user_details';

const EXAM_OPTIONS = [
  { value: 5, label: 'Last 5' },
  { value: 10, label: 'Last 10' },
  { value: 20, label: 'Last 20' },
  { value: 40, label: 'Last 40' },
  { value: 100, label: 'Last 100' },
];

const COLORS = ['#8884d8', '#82ca9d', '#ff7300', '#d8a1b2', '#a4de6c', '#ffc658', '#d0ed57', '#8dd1e1', '#a3a1a8', '#ff8042'];

const ExamsAttendance = () => {
  const { user } = useContext(Context);
  const [data, setData] = useState([]);
  const [selectedNumExams, setSelectedNumExams] = useState(10);
  const [calculatedHeight, setCalculatedHeight] = useState(320);
  const [pieOuterRadius, setPieOuterRadius] = useState(145);
  const [firmsList, setFirmsList] = useState([]);
  const [selectedClient, setSelectedClient] = useState(user.user_type === 'superAdmin'? '' : user.firm_id);

  useEffect(() => {
    const calculateHeightWidths = () => {
      const pageContainer = document.querySelector('.page-container');
      const divElement = document.querySelector('.chart-container');
      const divWidth = divElement.clientWidth;
      const scatterHeight = divWidth/2;
      const pageContHeight = (pageContainer.clientHeight) - 250;
      const availableHeight = pageContHeight - scatterHeight;
  
      let newHeight = availableHeight > 0 ? availableHeight : 320;
      if(newHeight > divWidth) {
        newHeight = divWidth;
      }
      let newRadius = (newHeight) / 2;
      
      setCalculatedHeight(newHeight);
      setPieOuterRadius(newRadius-15);
    };
    calculateHeightWidths();
  }, []);

  useEffect(() => {
    const fetchFirmsList = async() => {
      try{
        const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/ums/firms/list`);
        if(!data.success) throw new Error('Unable to fetch firms List');
        setFirmsList(data.results);
      } catch(e) {
        alert("Unable to fectch firms list");
        console.error(e);
      }
    }
    if(user.user_type === 'superAdmin') {
      fetchFirmsList();
    }
  }, []);


  const handleClientChange = (e) => {
    setSelectedClient(e.target.value);
  }

  useEffect(() => {
    const fetchData = async (numExams = 10) => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard/examsAttendance/${selectedClient}`, {
          params: { numExams }
        });
        setData(response.data);
      } catch (error) {
        console.error('Error fetching exam attendance data:', error);
      }
    };
    if(selectedClient && selectedNumExams) fetchData(selectedNumExams);
  }, [selectedNumExams, selectedClient]);

  const handleSelectChange = (event) => {
    setSelectedNumExams(parseInt(event.target.value));
  };

  const CustomLegend = ({ payload, maxVisibleItems = 1 }) => {
    const [expanded, setExpanded] = useState(false);

    const visibleItems = payload.slice(0, maxVisibleItems);
    const remainingItems = payload.slice(maxVisibleItems);
    const isExpandable = remainingItems.length > 0;

    return (
      <div style={{ position: 'relative', width: '100%', display: 'flex', flexWrap: 'wrap' }}>
        {visibleItems.map((entry, index) => (
          <div key={`item-${index}`} style={{ display: 'flex', alignItems: 'center', margin: '5px 10px' }}>
            <div
              style={{
                width: '10px',
                height: '10px',
                backgroundColor: entry.color,
                marginRight: '5px',
              }}
            ></div>
            <span>{entry.value}</span>
          </div>
        ))}
        {isExpandable && (
          <div
            style={{ margin: '5px 10px', color: '#888', position: 'relative' }}
            onMouseEnter={() => setExpanded(true)}
            onMouseLeave={() => setExpanded(false)}
          >
            +{remainingItems.length} more...
            {expanded && (
              <div
                style={{
                  position: 'absolute',
                  top: '-200px',
                  right: '0',
                  background: '#fff',
                  border: '1px solid #ccc',
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                  padding: '10px',
                  zIndex: 10,
                  maxHeight: '200px',
                  overflowY: 'auto',
                  whiteSpace: 'nowrap',
                }}
              >
                {remainingItems.map((entry, index) => (
                  <div key={`remaining-item-${index}`} style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                    <div
                      style={{
                        width: '10px',
                        height: '10px',
                        backgroundColor: entry.color,
                        marginRight: '5px',
                      }}
                    ></div>
                    <span>{entry.value}</span>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  return (
    <div class="chart-sub-container">
      <h3>Exam Vs Attendance</h3>
      <div className='d-flex gap-2 align-items-center justify-content-end'>
        {user.user_type === 'superAdmin' &&
          <div className='form-elements-container'>
            <div className='form-info-entry-area'>
              <select value={selectedClient} onChange={handleClientChange} className='form-select'>
                <option value='' disabled>Select Client</option>
                {firmsList.map(firm => (
                  <option key={firm._id} value={firm._id}>{firm.firm_name}</option>
                ))}
              </select>
            </div>
          </div>
        }

        <div className='form-elements-container row float-end'>
          <div className='form-info-entry-area'>
            <select id="numExamsSelect" value={selectedNumExams} onChange={handleSelectChange} className='form-select'>
              {EXAM_OPTIONS.map(option => (
                <option key={option.value} value={option.value}>{option.label}</option>
              ))}
            </select>
          </div>
        </div>
      </div>
      {
        selectedClient ?
          <div className='row'>
            <ResponsiveContainer width="100%" height={calculatedHeight}>
              <PieChart >
                <Pie
                  data={data}
                  dataKey="studentCount"
                  nameKey="examName"
                  cx="50%"
                  cy="50%"
                  outerRadius={pieOuterRadius}
                  fill="#8884d8"
                >
                  {data.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                      className="pie-cell-3d pie-cell-3d-gradient" // Added classes
                    />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
            <CustomLegend payload={data.map((entry, index) => ({
              value: entry.examName,
              color: COLORS[index % COLORS.length],
            }))} />
          </div>
          :
          <div className='d-flex align-items-center justify-content-center h-100 w-100'>
            <p className='nexus-color-primary-note text-center m-5'>Please Select an Organisation</p>
          </div>
      }
    </div>
  );
};

export default ExamsAttendance;
