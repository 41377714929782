// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.is_correct_radio {
  position: relative;
  left: -5px;
  cursor: pointer;
}
.ql-container{
  overflow-y: auto;
  height: 70%;
}
.ql-editor{
  word-break: break-word;
  overflow-y: auto;
  height: 100%;
}

.question-editorContainer {
  /* height:50vh; */
}
.quill {
  height:"100%";
  overflow-x: 'none';
  overflow-y: 'auto';
  word-break: 'break-all';
  width: 100%;
}

figure table  thead, tbody, tfoot, tr, td, th  {
    border-width: 1px !important;
}`, "",{"version":3,"sources":["webpack://./src/components/QuestionForm/questionForm.css"],"names":[],"mappings":";AACA;EACE,kBAAkB;EAClB,UAAU;EACV,eAAe;AACjB;AACA;EACE,gBAAgB;EAChB,WAAW;AACb;AACA;EACE,sBAAsB;EACtB,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,iBAAiB;AACnB;AACA;EACE,aAAa;EACb,kBAAkB;EAClB,kBAAkB;EAClB,uBAAuB;EACvB,WAAW;AACb;;AAEA;IACI,4BAA4B;AAChC","sourcesContent":["\n.is_correct_radio {\n  position: relative;\n  left: -5px;\n  cursor: pointer;\n}\n.ql-container{\n  overflow-y: auto;\n  height: 70%;\n}\n.ql-editor{\n  word-break: break-word;\n  overflow-y: auto;\n  height: 100%;\n}\n\n.question-editorContainer {\n  /* height:50vh; */\n}\n.quill {\n  height:\"100%\";\n  overflow-x: 'none';\n  overflow-y: 'auto';\n  word-break: 'break-all';\n  width: 100%;\n}\n\nfigure table  thead, tbody, tfoot, tr, td, th  {\n    border-width: 1px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
