import Home from '../pages/Home/home';
import Questions from '../components/Questions/questions';
import QuestionsGenerator from '../components/QuestionsGenerator/questionsGenerator';
import Courses from '../components/Courses/courses';
import Topics from '../components/Topics/topics';
import Default from '../components/Default/default';
import Subjects from '../components/Subjects/subjects';
import LearningOutcomes from '../components/Outcomes/outcomes';
import ExamPatterns from '../components/ExamPatterns/examPatterns';
import Exams from '../components/Exams/exams';
import Users from '../components/Users/users';
import Roles from '../components/Roles/roles';
import ExamComponent from '../components/ExamComponent/examComponent';
import CreateExamForm from '../components/AddExamForm/AddExamForm';
import CreateExamPattern from '../components/AddExamPatternForm/AddExamPatternForm';
import UpdateExam from '../components/UpdateExamForm/updateExam';
import CreateUser from '../components/ProfileCreate/create';
import SMTPSettings from '../components/SMTPSettings/smtpSettings';
import EmailTemplates from '../components/EmailTemplates/emailTemplates';
import Groups from '../components/UserGroups/groups';
import UpdateUser from '../components/ProfileUpdate/update';
import {Route} from 'react-router-dom';
import NotFound from '../pages/NotFound/NotFound';
import MyProfile from '../components/MyProfile/myprofile';
import Firms from '../components/Firms/firms';
import CreateFirm from '../components/FirmCreate/createFirm';
import FirmUpdate from '../components/FirmUpdate/updateFirm';

export const routes = [ 
  {
    path: '/',
    permissionType: '',
    userTypes: ['admin', 'superAdmin', 'user'],
    subRoutes: [
      { path: '', permissionType: '',userTypes: ['admin', 'superAdmin', 'user'], element: <Home />,
        subRoutes: [
          {path:'', permissionType: '',userTypes: ['admin', 'superAdmin', 'user'], element: <Default/>},
          {
            path: 'exams',
            permissionType: 'viewExams',
            userTypes: ['admin', 'superAdmin', 'user'],
            subRoutes: [
              { path: '', permissionType: 'viewExams',userTypes: ['admin', 'superAdmin', 'user'], element: <Exams /> },
              { path: ':id',permissionType: '',userTypes: ['admin', 'superAdmin', 'user'], element: <ExamComponent /> },
              { path: 'create', permissionType: 'createExams',userTypes: ['admin', 'superAdmin', 'user'], element: <CreateExamForm /> },
              { path: 'update/:id', permissionType: 'editExams',userTypes: ['admin', 'superAdmin', 'user'], element: <UpdateExam /> },
            ],
          },
          {
            path: 'users',
            permissionType: 'viewUsers',
            userTypes: ['admin', 'superAdmin', 'user'],
            subRoutes: [
              { path: '',permissionType: 'viewUsers',userTypes: ['admin', 'superAdmin', 'user'], element: <Users /> },
              { path: 'create-profile', permissionType: 'createUsers',userTypes: ['admin', 'superAdmin', 'user'], element: <CreateUser /> },
              { path: 'update-profile', permissionType: 'editUsers',userTypes: ['admin', 'superAdmin', 'user'], element: <UpdateUser /> },
            ],
          },
          {
            path: 'roles',
            permissionType: 'viewRoles',userTypes: ['admin', 'superAdmin', 'user'],
            subRoutes: [
              { path: '', permissionType: 'viewTopics',userTypes: ['admin', 'superAdmin', 'user'],element: <Roles /> },
              // { path: 'create', permissionType: 'createTopics', element: <CreateTopic /> },
              // { path: 'update/:id', permissionType: 'updateTopics', element: <UpdateTopic /> },
            ],
          },
          {
            path: 'courses',
            permissionType: 'viewCourses',userTypes: ['admin', 'superAdmin', 'user'],
            subRoutes: [
              { path: '',permissionType: 'viewCourses',userTypes: ['admin', 'superAdmin', 'user'], element: <Courses /> },
              // { path: 'create', permissionType: 'createCourses', element: <CreateCourse /> },
              // { path: 'update/:id', permissionType: 'updateCourses', element: <UpdateCourse /> },
            ],
          },
          {
            path: 'groups',
            permissionType: 'viewGroups',userTypes: ['admin', 'superAdmin', 'user'],
            subRoutes: [
              { path: '', permissionType: 'viewGroups',userTypes: ['admin', 'superAdmin', 'user'],element: <Groups /> },
              // { path: 'create', permissionType: 'createGroups', element: <CreateGroup /> },
              // { path: 'update/:id', permissionType: 'updateGroups', element: <UpdateGroup /> },
            ],
          },
          {
            path: 'subjects',
            permissionType: 'viewSubjects',userTypes: ['admin', 'superAdmin', 'user'],
            subRoutes: [
              { path: '',permissionType: 'viewSubjects',userTypes: ['admin', 'superAdmin', 'user'], element: <Subjects /> },
              // { path: 'create', permissionType: 'createSubjects', element: <CreateSubject /> },
              // { path: 'update/:id', permissionType: 'updateSubjects', element: <UpdateSubject /> },
            ],
          },
          {
            path: 'questions',
            permissionType: 'viewQuestions',userTypes: ['admin', 'superAdmin', 'user'],
            subRoutes: [
              { path: '',permissionType: 'viewQuestions',userTypes: ['admin', 'superAdmin', 'user'], element: <Questions /> },
              // { path: 'create', permissionType: 'createSubjects', element: <CreateSubject /> },
              // { path: 'update/:id', permissionType: 'updateSubjects', element: <UpdateSubject /> },
            ],
          },
          {
            path: 'generateQuestions',
            permissionType: 'viewQuestions',userTypes: ['admin', 'superAdmin', 'user'],
            subRoutes: [
              { path: '',permissionType: 'viewQuestions',userTypes: ['admin', 'superAdmin', 'user'], element: <QuestionsGenerator /> },
              // { path: 'create', permissionType: 'createSubjects', element: <CreateSubject /> },
              // { path: 'update/:id', permissionType: 'updateSubjects', element: <UpdateSubject /> },
            ],
          },
          {
            path: 'topics',
            permissionType: 'viewTopics',userTypes: ['admin', 'superAdmin', 'user'],
            subRoutes: [
              { path: '', permissionType: 'viewTopics',userTypes: ['admin', 'superAdmin', 'user'],element: <Topics /> },
              // { path: 'create', permissionType: 'createTopics', element: <CreateTopic /> },
              // { path: 'update/:id', permissionType: 'updateTopics', element: <UpdateTopic /> },
            ],
          },
          {
            path: 'outcomes',
            permissionType: 'viewLearningOutcomes',userTypes: ['admin', 'superAdmin', 'user'],
            subRoutes: [
              { path: '', permissionType: 'viewLearningOutcomes',userTypes: ['admin', 'superAdmin', 'user'],element: <LearningOutcomes /> },
              // { path: 'create', permissionType: 'createLearningOutcomes', element: <CreateLearningOutcome /> },
              // { path: 'update/:id', permissionType: 'updateLearningOutcomes', element: <UpdateLearningOutcome /> },
            ],
          },
          {
            path: 'examPatterns',
            permissionType: 'viewExamPatterns',userTypes: ['admin', 'superAdmin', 'user'],
            subRoutes: [
              { path: '',permissionType: 'viewExamPatterns',userTypes: ['admin', 'superAdmin', 'user'], element: <ExamPatterns /> },
              { path: 'create', permissionType: 'createExamPatterns',userTypes: ['admin', 'superAdmin', 'user'], element: <CreateExamPattern /> },
              // { path: 'update/:id', permissionType: 'updateExamPatterns', element: <UpdateExamPattern /> },
            ],
          },
          {
            path: 'SMTP-Settings',
            permissionType: 'viewSMTPSettings',userTypes: ['admin', 'superAdmin', 'user'],
            subRoutes: [
              { path: '',permissionType: '',userTypes: ['admin', 'superAdmin', 'user'], element: <SMTPSettings /> }
            ]
          },
          {
            path: 'Email-Templates',
            permissionType: 'viewEmailTemplates',userTypes: ['admin', 'superAdmin', 'user'],
            subRoutes: [
              { path: '',permissionType: '',userTypes: ['admin', 'superAdmin', 'user'], element: <EmailTemplates /> }
            ]
          },
          {
            path: 'My-Profile',
            permissionType: '',userTypes: ['admin', 'superAdmin', 'user'],
            subRoutes: [
              { path: '',permissionType: '',userTypes: ['admin', 'superAdmin', 'user'], element: <MyProfile/> }
            ]
          },
          {
            path: 'firms',
            permissionType: '',userTypes: ['superAdmin'],
            subRoutes: [
              { path: '',permissionType: '',userTypes: ['superAdmin'], element: <Firms/> }
            ]
          },{
            path: 'create-firm',
            permissionType: '',userTypes: ['superAdmin'],
            subRoutes: [
              { path: '',permissionType: '',userTypes: ['superAdmin'], element: <CreateFirm/> }
            ]
          },{
            path: 'update-firm',
            permissionType: '',userTypes: ['superAdmin'],
            subRoutes: [
              { path: '',permissionType: '',userTypes: ['superAdmin'], element: <FirmUpdate/> }
            ]
          },
          {
            path: '*',
            permissionType: '',userTypes: ['admin', 'superAdmin', 'user','student'],
            subRoutes: [
              { path: '*',permissionType: '',userTypes: ['admin', 'superAdmin', 'user','student'], element: <NotFound /> },
            ],
          }
        ]
       },
    ],
  },
  
   
  ];



export const superAdminRoutes = [

]

//--------------------------------------------------------------

//   <Context.Provider value={{ user: userDetails }}>
//             <BrowserRouter>
//               <Routes>
//                 <Route path="/" element={<Home />}>
//                   <Route path="" element={<Default />} />
//                   <Route path="questions" element={<Questions />} />
//                   <Route path="SMTP-Settings" element={<SMTPSettings />} />
//                   <Route path="courses" element={<Courses />} />
//                   <Route path="subjects" element={<Subjects />} />
//                   <Route path="topics" element={<Topics />} />
//                   <Route path="outcomes" element={<Outcomes />} />
//                   <Route path="examPatterns">
//                     <Route exact path="" element={<ExamPatterns />} />
//                     <Route exact path="create" element={<AddExamPatternForm />} />
//                   </Route>
//                   <Route caseSensitive exact path="create-profile" element={<CreateUser />} />
//                   <Route caseSensitive exact path='update-profile' element={<Update />} />
//                   <Route path="exams">
//                     <Route exact path="" element={<Exams />} />
//                     <Route exact path=":id" element={<ExamComponent />} />
//                     <Route exact path="create" element={<CreateExamForm />} />
//                     <Route exact path="update/:id" element={<UpdateExam />} />
//                   </Route>
//                   <Route path="users" element={<Users />} />
//                   <Route path="privileges" element={<Privileges />} />
//                   <Route path="roles" element={<Roles />} />
//                   <Route path="groups" element={<Groups />} />
//                   <Route caseSensitive exact path='Email-Templates' element={<EmailTemplates />} />
//                   <Route exact path="*" element={<Default />} />
//                 </Route>
//               </Routes>
//             </BrowserRouter>
//           </Context.Provider>

//------------------------------------------------------------

// import './App.css';
// import { BrowserRouter, Routes, Route } from 'react-router-dom';
// import Login from './pages/Login/login';
// import Home from './pages/Home/home';
// import Questions from './components/Questions/questions';
// import Courses from './components/Courses/courses';
// import Topics from './components/Topics/topics';
// import Default from './components/Default/default';
// import Subjects from './components/Subjects/subjects';
// import Outcomes from './components/Outcomes/outcomes';
// import { useState, useEffect } from 'react';
// import Cookies from 'js-cookie';
// import Context from './user_details';
// import ExamPatterns from './components/ExamPatterns/examPatterns';
// import Exams from './components/Exams/exams';
// import Users from './components/Users/users';
// import Privileges from './components/Privileges/privileges';
// import Roles from './components/Roles/roles';
// import ExamComponent from './components/ExamComponent/examComponent';
// import CreateExamForm from './components/AddExamForm/AddExamForm';
// import AddExamPatternForm from './components/AddExamPatternForm/AddExamPatternForm';
// import UpdateExam from './components/UpdateExamForm/updateExam';
// import Update from './components/ProfileUpdate/update';
// import ProfileCreate from './components/ProfileCreate/create';
// import SMTPSettings from './components/SMTPSettings/smtpSettings';
// import ForgotPassword from './components/ForgotPassword/forgotPassword';
// import EmailTemplates from './components/EmailTemplates/emailTemplates';
// import Groups from './components/UserGroups/groups';
// import permissionsHelper from './helpers/permissionsHelper'
// import SpinnerLoader from './helpers/spinner-loader';

export const generateRouter = async (filteredRouter) => {
  // Helper function to generate child routes recursively
  const generateChildRoutes = async (route) => {
    if (route.subRoutes && route.subRoutes.length > 0) {
      return (
        <Route key={route.path} path={route.path} element={route.element}>
          {await Promise.all(route.subRoutes.map(subRoute => generateChildRoutes(subRoute)))}
        </Route>
      );
    } else {
      return (
        <Route key={route.path} path={route.path} element={route.element} />
      );
    }
  };

  // Generate the main router structure
  const availableRouter = await Promise.all(
    filteredRouter.map(async (route) => {
      return await generateChildRoutes(route);
    })
  );

  return availableRouter;
};