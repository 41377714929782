import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import Context from '../../user_details';

const complexityLabels = {
  1: 'Easy',
  2: 'Medium',
  3: 'Hard',
};

const questionTypeLabels = {
  1: 'Multiple Choice',
  2: 'True / False',
  3: 'Check Boxes',
  4: 'Comprehensive',
  5: 'File Upload',
};

// Define colors for each question type
const questionTypeColors = {
  1: '#8884d8',  // Multiple Choice
  2: '#82ca9d',  // True / False
  3: '#ff7300',  // Check Boxes
  4: '#d8a1b2',  // Comprehensive
  5: '#a4de6c',  // File Upload
};

const QuestionAnalysis = () => {
  const { user } = useContext(Context);
  const [data, setData] = useState([]);
  const [selectedComplexity, setSelectedComplexity] = useState(null);
  const [calculatedHeight, setCalculatedHeight] = useState(320);
  const [firmsList, setFirmsList] = useState([]);
  const [selectedClient, setSelectedClient] = useState(user.user_type === 'superAdmin'? '' : user.firm_id);

  

  useEffect(() => {
    const calculateHeightWidths = () => {
      const pageContainer = document.querySelector('.page-container');
      const divElement = document.querySelector('.chart-container');
      const divWidth = divElement.clientWidth;
      const scatterHeight = divWidth/2;
      const pageContHeight = (pageContainer.clientHeight) - 215;
      const availableHeight = pageContHeight - scatterHeight;
  
      let newHeight = availableHeight > 0 ? availableHeight : 320;
      if(newHeight > divWidth) {
        newHeight = divWidth;
      }
      setCalculatedHeight(newHeight);
    };
    calculateHeightWidths();
  }, []);

  useEffect(() => {
    const fetchFirmsList = async() => {
      try{
        const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/ums/firms/list`);
        if(!data.success) throw new Error('Unable to fetch firms List');
        setFirmsList(data.results);
      } catch(e) {
        alert("Unable to fectch firms list");
        console.error(e);
      }
    }
    if(user.user_type === 'superAdmin') {
      fetchFirmsList();
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const questionResponse = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard/questionAnalysis/${selectedClient}`);
        // Format the data for grouped bars
        const formattedData = questionResponse.data.questionsByTypeAndDifficulty.map(item => ({
          complexity: item.complexity,
          ...item.questionTypes.reduce((acc, qt) => ({
            ...acc,
            [`questionType_${qt.question_type}`]: qt.count
          }), {})
        }));
        
        setData(formattedData);
      } catch (error) {
        console.error('Error fetching question analysis:', error);
      }
    };

    if(selectedClient) fetchData();
  }, [selectedClient]);

  const handleClientChange = (e) => {
    setSelectedClient(e.target.value);
  }

  const handleBarClick = (data) => {
    if (!data || !data.activePayload) return;
    const { complexity } = data.activePayload[0].payload;
    setSelectedComplexity(complexity);
  };

  const formatComplexity = (value) => complexityLabels[value] || value;

  const formatQuestionType = (value) => questionTypeLabels[value] || value;

  return (
    <div class="chart-sub-container">
      <h3>Question Bank Analysis</h3>
      <div className='d-flex gap-2 align-items-center justify-content-end'>
        {user.user_type === 'superAdmin' &&
          <div className='form-elements-container'>
            <div className='form-info-entry-area'>
              <select value={selectedClient} onChange={handleClientChange} className='form-select'>
                <option value='' disabled>Select Client</option>
                {firmsList.map(firm => (
                  <option key={firm._id} value={firm._id}>{firm.firm_name}</option>
                ))}
              </select>
            </div>
          </div>
        }
      </div>
      {
        selectedClient?
          <ResponsiveContainer width="100%" height={calculatedHeight}>
            <BarChart data={data} onClick={(event) => handleBarClick(event)}>
              <XAxis dataKey="complexity" tickFormatter={formatComplexity} />
              <YAxis />
              <Tooltip
                formatter={(value) => `${value}`}
                labelFormatter={(label) => `Complexity: ${formatComplexity(label)}`}
              />
              <Legend />
              {Object.keys(questionTypeLabels).map(questionType => (
                <Bar
                  key={`questionType-${questionType}`}
                  dataKey={`questionType_${questionType}`}
                  fill={questionTypeColors[questionType]}
                  name={formatQuestionType(questionType)}
                />
              ))}
            </BarChart>
          </ResponsiveContainer>
          :
          <div className='d-flex align-items-center justify-content-center h-100 w-100'>
            <p className='nexus-color-primary-note text-center m-5'>Please Select an Organisation</p>
          </div>
      }
    </div>
  );
};

export default QuestionAnalysis;
