import React, { useEffect, useState, useContext } from 'react';
import { Line } from 'react-chartjs-2';
import axios from 'axios';
import Context from '../../user_details';
import SpinnerLoader from '../../helpers/spinner-loader';

const ExamsOverTimeChart = () => {
  const { user } = useContext(Context);
  const [chartData, setChartData] = useState({});
  const [loading, setLoading] = useState(user.user_type !== 'superAdmin');
  const [error, setError] = useState(null);
  const [timeRange, setTimeRange] = useState('Last One Year');
  const [selectedClient, setSelectedClient] = useState(user.user_type === 'superAdmin'? '' : user.firm_id);
  const [firmsList, setFirmsList] = useState([]);

  const timeRanges = [
    'Last One Week',
    'Last One Month',
    'Last One Year',
    'All Till Date'
  ];

  useEffect(() => {
    const fetchFirmsList = async() => {
      try{
        setLoading(true);
        const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/ums/firms/list`);
        if(!data.success) throw new Error('Unable to fetch firms List');
        setFirmsList(data.results);
        
      } catch(e) {
        alert("Unable to fectch firms list");
        console.error(e);
      } finally {
        setLoading(false);
      }
    }
    if(user.user_type === 'superAdmin') {
      fetchFirmsList();
    }
  }, []);

  useEffect(() => {
    const fetchExamData = async () => {
      setChartData({});
      setLoading(true);
      setError(null);
      try {
        const examsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard/overview/${selectedClient}`, {
          params: { timeRange }
        });
        const exams = examsResponse.data;

        const dates = exams.map(exam => new Date(exam.startDate).toLocaleDateString());
        const examCounts = dates.reduce((acc, date) => {
          acc[date] = (acc[date] || 0) + 1;
          return acc;
        }, {});

        const sortedDates = Object.keys(examCounts).sort((a, b) => new Date(a) - new Date(b));
        const counts = sortedDates.map(date => examCounts[date]);

        
        setLoading(() => {
          setChartData({
            labels: sortedDates,
            datasets: [
              {
                label: 'Number of Exams',
                data: counts,
                backgroundColor: 'rgba(75, 192, 192, 0.5)', // More opacity for a softer look
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 2,
                tension: 0.4,
                pointBackgroundColor: 'rgba(75, 192, 192, 1)',
                pointBorderColor: '#3a4f96',
                pointBorderWidth: 2,
                pointRadius: 6,
                pointHoverRadius: 8,
                fill: 'origin',
                shadowOffsetX: 5, // Simulating 3D effect
                shadowOffsetY: 5,
                shadowBlur: 10,
                shadowColor: 'rgba(0, 0, 0, 0.3)',
              }
            ]
          });
          return false;
        });
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    if(selectedClient && timeRange) fetchExamData();
  }, [timeRange, selectedClient]);

  const handleTimeRangeChange = (event) => {
    setTimeRange(event.target.value);
  };

  const handleClientChange = (e) => {
    setSelectedClient(e.target.value);
  }

  if (loading) return <div className="ht60vh w-100 d-flex justify-content-center align-items-center">
                        <SpinnerLoader />
                      </div>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <div className="chart-sub-container">
      <h3>Exams Over Time</h3>
      <div className='d-flex gap-2 align-items-center justify-content-end'>
        {user.user_type === 'superAdmin' && 
          <div className='form-elements-container'>
            <div className='form-info-entry-area'>
                <select value={selectedClient} onChange={handleClientChange} className='form-select'>
                  <option value='' disabled>Select Client</option>
                {firmsList.map(firm => (
                    <option key={firm._id} value={firm._id}>{firm.firm_name}</option>
                ))}
                </select>
            </div>
          </div>
        }
        <div className='form-elements-container'>
          <div className='form-info-entry-area'>
              <select id="timeRange" value={timeRange} onChange={handleTimeRangeChange} className='form-select'>
              {timeRanges.map(range => (
                  <option key={range} value={range}>{range}</option>
              ))}
              </select>
          </div>
        </div>
      </div>
      {selectedClient?
         Object.keys(chartData).length > 0 ?
          <Line data={chartData} 
                options={{
                  scales: {
                    x: {
                      display: false
                    }
                  }
                }}
          />
          :
          <p className='nexus-color-primary-note text-center fw-medium'>Something Went Wrong. Please Try again later</p>
        :
        <div className='d-flex align-items-center justify-content-center h-100 w-100'>
          <p className='nexus-color-primary-note text-center m-5'>Please Select an Organisation</p>
        </div>
      }
    </div>
  );
};

export default ExamsOverTimeChart;
