import {useEffect,useState,useContext} from 'react';
import {Modal} from "react-bootstrap";
import SpinnerLoader from '../../helpers/spinner-loader';
import NoDataImage from '../../assets/no-data.jpg';
import "./users.css";
import axios from 'axios';
import Context from "../../user_details";

const UserEmailStatus = () => {
    const [showModal, setShowModal] = useState(false);
    const [tableLoader, setTableLoader] = useState(false);
    const [tableData, setTableData] = useState([]);
    const {user} = useContext(Context);
    const [selecteFirmId, setSelectedFirmId] = useState(user.user_type == 'superAdmin'? "" : user.firm_id);
    const [firms,setFirms] = useState([]);

    useEffect(() => {
        const getFirmsList = async() => {
            try {
                setTableLoader(true);
                const {data: response} = await axios.get(`${process.env.REACT_APP_API_URL}/ums/firms/list`);
                if(!response.success) throw new Error("Error: Fetching firms list");
                setFirms(response.results);
            }
            catch(e) {
                setFirms([])
                console.error(e)
            } finally {
                setTableLoader(false);
            }
        }
        getFirmsList();
    }, [])

    useEffect(() => {
        const fetchUserEmailStatus = async() => {
            // const response = await axios.get(`${process.env.REACT_APP_API_URL}/getUsers`)
        }
        if(showModal) {
            if(selecteFirmId) {
                fetchUserEmailStatus();
            }
        }
    }, [showModal])


    const emailSVG = (
        <span>
            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" height={24}>
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
                <path d="M4 9.00005L10.2 13.65C11.2667 14.45 12.7333 14.45 13.8 13.65L20 9" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> 
                <path d="M3 9.17681C3 8.45047 3.39378 7.78123 4.02871 7.42849L11.0287 3.5396C11.6328 3.20402 12.3672 3.20402 12.9713 3.5396L19.9713 7.42849C20.6062 7.78123 21 8.45047 21 9.17681V17C21 18.1046 20.1046 19 19 19H5C3.89543 19 3 18.1046 3 17V9.17681Z" stroke="#ffffff" stroke-width="2" stroke-linecap="round"></path> 
            </g></svg>
        </span>
    )

    const Loader = (
        <div className='d-flex align-items-center justify-content-center'><SpinnerLoader/></div>
    )

    const emptyData = (
        <div >
            <img src={NoDataImage} height={400}/>
        </div>
    )

    const modalBody = () => {
        if(tableLoader) {
            return <div className='p-5 m-5'>{Loader}</div>;
        } else {
            return (
               <>
                <div className='w-100 h-100 '>
                    <label className='nexus-label-500 d-flex gap-2 container p-3'> 
                        <span className='col-3'> Firm </span>
                        <select className='nexus-dropdown w-100' value={selecteFirmId} onChange={(e) => {setSelectedFirmId(e.target.value)}}>
                            <option value="">--Select a Firm ---</option>
                            {firms.map((firm, index) => (
                                <option key={index} value={firm._id}> {firm.firm_name}</option>
                            ))}
                        </select>
                    </label>
                    {tableOfContent()}
                    
                </div>
               
              </>
            )
        } 
    }

    const tableOfContent = () => {
        if (selecteFirmId) {
            if (tableData.length > 0 ) {
                return <table>asd</table>
            } else {
                return (
                    <>{emptyData}</>
                )
            }
        } else {
            return (
                <div className='w-100 h-100 p-5 nexus-label-500 nexus-color-primary-note d-flex justify-content-center nexus-border-secondary-dotted'> 
                    Please select Firm
                </div>
            )
        }
    }
    
    return (
        <div>
            <Modal show={showModal}>
                <Modal.Body>
                    {modalBody()}
                </Modal.Body>
                <Modal.Footer className='d-flex align-items-center justifiy-content-end'>
                    <button className='nexus-button-danger-outline' onClick={() => {setShowModal(false)}}>
                        Close
                    </button>
                </Modal.Footer>
            </Modal>
            <button className="nexus-button-primary d-flex align-items-center gap-1 " style={{fontSize: "14px", fontWeight: "500"}} onClick={() => {setShowModal(true)}}>
                {emailSVG} Email Status
            </button>
        </div>
    )
}

export default UserEmailStatus;